import useAuth from "../hooks/useAuth";
import useSession from "../hooks/useSession";
import { getToken } from "../tokens";

//import useEnvironment from "../hooks/useSession";
import axios, { all } from "axios";

import { useState, useEffect, useMemo } from "react";
import { json, useNavigate } from "react-router-dom";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormControl, InputLabel, TextField, Card, Link } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import moment from "moment";

import DeleteIcon from "@mui/icons-material/Delete";
import CallReceivedIcon from "@mui/icons-material/CallReceived";

import ProductDetails from "../components/productDetails";
import hostsConfig from "../hostconfig.json";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import IconButton from "@mui/material/IconButton";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CalculateIcon from "@mui/icons-material/Calculate";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import BrowserNotSupportedIcon from "@mui/icons-material/BrowserNotSupported";

import FormControlLabel from '@mui/material/FormControlLabel';

import Switch from '@mui/material/Switch';

const EditProduct = () => {
    const { auth, setAuth } = useAuth();
    const { session, setSession } = useSession();

    const [loading, setLoading] = useState(false);

    const [filterSupplier, setFilterSupplier] = useState("");

    //const [purchases, setPurchases] = useState([]); //toDelete ???
    // const [allPurchases, setAllPurchases] = useState([]);
    //const [selectedPurchase, setSelectedPurchase] = useState(""); //toDelete???

    const [steps, setSteps] = useState();
    const [activeStep, setActiveStep] = useState(1);

    const [numberOfProducts, setNumberOfProducts] = useState(1);

    const [schools, setSchools] = useState([]);
    const [schoolDropdown, setSchoolDropdown] = useState("");
    const [selectedSchool, setSelectedSchool] = useState("");
    const [selectedSchoolObj, setSelectedSchoolObj] = useState(null);

    const [locations, setLocations] = useState([]);
    const [locationDropdown, setLocationDropdown] = useState("");

    const [statuses, setStatuses] = useState([]);
    const [statusDropdown, setStatusDropdown] = useState("");

    const [actionTypes, setActionTypes] = useState([]);
    const [actionTypeDropdown, setActionTypeDropdown] = useState("");

    const [email, setEmail] = useState("");

    const [categories, setCategories] = useState([]);
    const [categoryDropdown, setCategoryDropdown] = useState("");

    const [subcategories, setSubcategories] = useState([]);
    const [subcategoryDropdown, setSubcategoryDropdown] = useState("");

    const [manufacturers, setManufacturers] = useState([]);
    const [manufacturerDropdown, setManufacturerDropdown] = useState("");

    const [modelNumber, setModelNumber] = useState("");
    const [productName, setProductName] = useState("");
    const [serialNumber, setSerialNumber] = useState("");
    const [purchaseDateFrom, setPurchaseDateFrom] = useState("");
    const [purchaseDateTo, setPurchaseDateTo] = useState("");

    const [suppliers, setSuppliers] = useState([]);
    const [supplierDropdown, setSupplierDropdown] = useState("");

    const [optionSupplier, setOptionSupplier] = useState("");
    const [inputSupplier, setInputSupplier] = useState("");
    const [supplierNotFound, setSupplierNotFound] = useState(false);

    const [priceVATinclFrom, setPriceVATinclFrom] = useState("");
    const [priceVATinclTo, setPriceVATinclTo] = useState("");

    const [invoiceNrExt, setInvoiceNrExt] = useState("");
    const [invoiceNrInt, setInvoiceNrInt] = useState("");
    const [invoiceNrSch, setInvoiceNrSch] = useState("");

    const [dialogFunction, setDialogFunction] = useState("");
    const [openMessage, setOpenMessage] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogAction, setDialogAction] = useState("");

    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    const [productPage, setProductPage] = useState(0);
    const [productMultyPage, setProductMultyPage] = useState(0);
    //const [purchasePage, setPurchasePage] = useState(0); //toDelete
    const [filesPage, setFilesPage] = useState(0);

    const [products, setProducts] = useState([]);
    const [productHistory, setProductHistory] = useState([]);
    const [productDetailsObj, setProductDetailsObj] = useState();
    const [productLastStatusObj, setProductLastStatusObj] = useState("");
    const [productLastPurchaseObj, setProductLastPurchaseObj] = useState("");
    const [productLastLocationObj, setProductLastLocationObj] = useState("");

    const [selectedProduct, setSelectedProduct] = useState();

    // const [showFilters, setShowFilters] = useState(true);
    // const [showProductDetails, setShowProductDetails] = useState(false);

    const [productSchool, setProductSchool] = useState("");
    const [productLocation, setProductLocation] = useState("");
    const [productInputLocation, setProductInputLocation] = useState("");
    const [locationNotes, setLocationNotes] = useState("");

    const [productNotes, setProductNotes] = useState("");
    const [productStatus, setProductStatus] = useState("");
    // const [productStatusNotes, setProductStatusNotes] = useState("");
    const [productHistoryNotes, setProductHistoryNotes] = useState("");
    const [productCategory, setProductCategory] = useState("");
    const [productSubcategory, setProductSubcategory] = useState("");
    const [productManufacturer, setProductManufacturer] = useState("");
    const [productInputManufacturer, setProductInputManufacturer] =
        useState("");
    const [productModelNumber, setProductModelNumber] = useState("");
    const [productProductName, setProductProductName] = useState("");
    const [productSerialNumber, setProductSerialNumber] = useState("");
    const [productDetailNotes, setProductDetailNotes] = useState("");
    const [productInsurance, setProductInsurance] = useState("");

    //const [filterPurchaseSupplier, setFilterPurchaseSupplier] = useState(""); //toDelete
    //const [filterPurchaseFrom_date, setFilterPurchaseFrom_date] = useState(""); //toDelete
    //const [filterPurchaseTo_date, setFilterPurchaseTo_date] = useState(""); //toDelete
    //const [filterPurchaseInvoiceNrExt, setFilterPurchaseInvoiceNrExt] = useState(""); //toDelete
    //const [filterPurchaseInvoiceNrInt, setFilterPurchaseInvoiceNrInt] = useState(""); //toDelete
    //const [filterPurchaseInvoiceNrSch, setFilterPurchaseInvoiceNrSch] = useState(""); //toDelete

    //const [addInPurchaseDisabled, setAddInPurchaseDisabled] = useState(true);
    //const [changePricesDisabled, setChangePricesDisabled] = useState(true);

    const [priceVATexcl, setPriceVATexcl] = useState("");
    const [VAT, setVAT] = useState("");
    const [priceVATincl, setPriceVATincl] = useState("");

    const [countConnectedProducts, setCountConnectedProducts] = useState(0);

    const [selectedRows, setSelectedRows] = useState([]);
    const [tabValue, setTabValue] = useState(0);

    const [newSupplierID, setNewSupplierID] = useState("");
    const [newPurchaseDate, setNewPurchaseDate] = useState("");
    const [invoicesSelectedPurchaseID, setInvoicesSelectedPurchaseID] =
        useState("");
    // const [newInvoiceNrExt, setNewInvoiceNrExt] = useState("");
    const [newInvoiceNrInt, setNewInvoiceNrInt] = useState("");
    // const [newInvoiceNrSch, setNewInvoiceNrSch] = useState("");
    const [newPurchaseNotes, setNewPurchaseNotes] = useState("");
    const [files, setFiles] = useState([]);
    const [newURLFiles, setNewURLFiles] = useState("");
    const [driveDropdown, setDriveDropdown] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedFilesModel, setSelectedFilesModel] = useState([]);

    //const [warningPurchaseExists, setWarningPurchaseExists] = useState(false);
    const [existingPurchase, setExistingPurchase] = useState(null);

    const [disableAddPurchase, setDisableAddPurchase] = useState(true);
    const [disableChangePurchase, setDisableChangePurchase] = useState(true);
    const [disableSavePurchaseChanges, setDisableSavePurchaseChanges] = useState(true);

    const [disableIsFileConnected, setDisableIsFileConnected] = useState(false);
    const [disableCanRemoveFile, setDisableCanRemoveFile] = useState(false);
    const [disableSupplier, setDisableSupplier] = useState(false);
    const [disablePurchaseDate, setDisablePurchaseDate] = useState(false);
    const [disableInvoiceNrInt, setDisableInvoiceNrInt] = useState(false);
    const [disablePurchaseNotes, setDisablePurchaseNotes] = useState(false);

    const [addSavePurchaseButtonLabel, setAddSavePurchaseButtonLabel] =
        useState("Add invoice");
    const [
        attachProductToPurchaseButtonLabel,
        setAttachProductToPurchaseButtonLabel,
    ] = useState("Attach product to invoice");

    const [filtersExplanded, setFiltersExplanded] = useState(true);
    const [filterFilesByName, setFilterFilesByName] = useState("");

    const [invoiceFilesDB, setInvoiceFilesDB] = useState([]);

    const [manufacturersChanged, setManufacturersChanged] = useState(0);

const [isFileConnected, setIsFileConnected] = useState(true);

    const navigate = useNavigate();

    const MimeTypeDictionary = {
        "application/vnd.google-apps.audio": "Audio",
        "application/vnd.google-apps.document": "Document",
        "application/vnd.google-apps.drawing": "Drawing",
        "application/vnd.google-apps.file": "File",
        "application/vnd.google-apps.folder": "Folder",
        "application/vnd.google-apps.form": "Form",
        "application/vnd.google-apps.fusiontable": "Fusion Table",
        "application/vnd.google-apps.map": "Map",
        "application/vnd.google-apps.photo": "Photo",
        "application/vnd.google-apps.presentation": "Presentation",
        "application/vnd.google-apps.script": "Script",
        "application/vnd.google-apps.site": "Site",
        "application/vnd.google-apps.spreadsheet": "Spreadsheet",
        "application/vnd.google-apps.unknown": "Unknown",
        "application/vnd.google-apps.video": "Video",
        "application/pdf": "PDF",
    };

    const columnsProducts: GridColDef[] = [
        {
            field: "action",
            headerName: "",
            width: 45,
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    //e.stopPropagation(); // don't select this row after clicking
                    //navigate(`/product/${params.id}`);
                    setActiveStep(2);
                };
                return (
                    <>
                        <Tooltip title="List of products" placement="top">
                            <ModeEditIcon onClick={onClick} />
                        </Tooltip>
                    </>
                );
            },
        },
        // {
        //     field: "modelNumber",
        //     headerName: "Model Number",
        //     width: 120,
        //     editable: false,
        // },
        {
            field: "categoryName",
            headerName: "Category",
            width: 120,
            editable: false,
        },
        {
            field: "subcategoryName",
            headerName: "Subcategory",
            width: 120,
            editable: false,
        },
        {
            field: "manufacturerName",
            headerName: "Manufacturer",
            width: 120,
            editable: false,
        },

        // {
        //     field: "statusName",
        //     headerName: "Status",
        //     width: 100,
        //     editable: false,
        // },
        {
            field: "statusLabel",
            headerName: "Status",
            width: 110,
            renderCell: (params) => (
                <Chip
                    label={params.value}
                    sx={{
                        backgroundColor: params.row.statusColor,
                        width: "100%",
                        borderRadius: 2,
                    }}
                />
            ),
        },

        {
            field: "serialNumber",
            headerName: "Serial Nr",
            width: 120,
            editable: false,
        },
        // {
        //     field: "changeDate",
        //     headerName: "Last change",
        //     width: 150,
        //     editable: false,
        // },
        // {
        //     field: "accountEmail",
        //     headerName: "Account Email",
        //     width: 150,
        //     editable: false,
        // },
        {
            field: "notes",
            headerName: "Notes",
            width: 150,
            editable: false,
        },
        {
            field: "gotoInvoice",
            headerName: "Invoice",
            sortable: false,
            width: 58,
            renderCell: (params) => {
                if (params?.row.currentPurchaseID !== null) {
                    const onClickList = (e) => {
                        setActiveStep(3);
                    };
                    return (
                        <>
                            <Tooltip title="List of products" placement="top">
                                <ReceiptIcon onClick={onClickList} />
                            </Tooltip>
                        </>
                    );
                } else {
                    return (
                        <>
                            <Tooltip title="List of products" placement="top">
                                <BrowserNotSupportedIcon />
                            </Tooltip>
                        </>
                    );
                }
            },
        },
        {
            field: "fileID",
            headerName: "Inv. file",
            width: 100,
            renderCell: (params) => {
                if (params.row.fileID !== null) {
                    // let filesObj = JSON.parse(params.row.URLfile);
                    //if fileObj.files.length > 0 then for every file in fileObj.files array, create a chip with the name of the file and a link to open the file in new tab else return null
                    return params.row.fileID !== null ? (
                        <Tooltip
                            title={params.row.fileName}
                            placement="top"
                            key={params.row.fileID}
                        >
                            <Link
                                href={
                                    "https://drive.google.com/file/d/" +
                                    params.row.fileID +
                                    "/view"
                                }
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FileOpenIcon />
                            </Link>
                        </Tooltip>
                    ) : (
                        <></>
                    );
                } else {
                    return (
                        <>
                            <Tooltip title="No files" placement="top">
                                <Chip
                                    label="No files"
                                    sx={{ width: "100%", borderRadius: 2 }}
                                />
                            </Tooltip>
                        </>
                    );
                }
            },
        },
    ];

    const columnsProducts_: GridColDef[] = [
        { field: "productID", headerName: "ID", width: 0 },
        {
            field: "action",
            headerName: "Goto product",
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    //e.stopPropagation(); // don't select this row after clicking
                    //navigate(`/product/${params.id}`);
                    setActiveStep(2);
                };
                return <Button onClick={onClick}>...</Button>;
            },
        },
        { field: "orgName", headerName: "School", width: 250 },
        { field: "locName", headerName: "Location", width: 250 },
        { field: "categoryName", headerName: "Category", width: 110 },
        {
            field: "oldestChangeDate",
            headerName: "First entry",
            width: 100,
            valueFormatter: (params) =>
                moment(params?.value).format("DD/MM/YYYY"),
        },
        { field: "manufacturerName", headerName: "Manufacturer", width: 110 },
        {
            field: "gotoInvoice",
            headerName: "Goto invoice",
            sortable: false,
            width: 150,
            renderCell: (params) => {
                if (params?.row.currentPurchaseID !== null) {
                    const onClickList = (e) => {
                        e.stopPropagation(); // don't select this row after clicking
                        //add in session.goback.page the 'editProduct' string and in the session.goback.id selected productID

                        setSession({
                            ...session,
                            goback: {
                                ...session.goback,
                                page: "editProduct",
                                id: params?.row.productID,
                            },
                        });

                        navigate(
                            `/purchase/${params?.row.currentPurchaseID}/productList`
                        );
                    };
                    const onClickDetails = (e) => {
                        e.stopPropagation(); // don't select this row after clicking

                        setSession({
                            ...session,
                            goback: {
                                ...session.goback,
                                page: "editProduct",
                                id: params?.row.productID,
                            },
                        });

                        navigate(
                            `/purchase/${params?.row.currentPurchaseID}/invoiceDetails`
                        );
                    };
                    return (
                        <>
                            <Tooltip title="List of products" placement="top">
                                <ReceiptIcon onClick={onClickList} />
                            </Tooltip>
                            <Tooltip title="Invoice details" placement="top">
                                <PictureAsPdfIcon onClick={onClickDetails} />
                            </Tooltip>
                        </>
                    );
                } else {
                    return "";
                }
            },
        },

        {
            field: "fileID",
            headerName: "Inv. file",
            width: 100,
            renderCell: (params) => {
                if (params.row.fileID !== null) {
                    // let filesObj = JSON.parse(params.row.URLfile);
                    //if fileObj.files.length > 0 then for every file in fileObj.files array, create a chip with the name of the file and a link to open the file in new tab else return null
                    return params.row.fileID !== null ? (
                        <Tooltip
                            title={params.row.fileName}
                            placement="top"
                            key={params.row.fileID}
                        >
                            <Link
                                href={
                                    "https://drive.google.com/file/d/" +
                                    params.row.fileID +
                                    "/view"
                                }
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FileOpenIcon />
                            </Link>
                        </Tooltip>
                    ) : (
                        <></>
                    );
                } else {
                    return (
                        <>
                            <Tooltip title="No files" placement="top">
                                <Chip
                                    label="No files"
                                    sx={{ width: "100%", borderRadius: 2 }}
                                />
                            </Tooltip>
                        </>
                    );
                }
            },
        },

        // { field: "latestPurchaseID", headerName: "latestPurchaseID", width: 150 },

        // { field: "notes", headerName: "Notes", width: 150 },
        // { field: "statusName", headerName: "Status", width: 150 },
    ];

    const columnsProductsMulty: GridColDef[] = [
        { field: "productID", headerName: "ID", width: 0 },
        { field: "categoryName", headerName: "Category", width: 110 },
        { field: "subcategoryName", headerName: "Subcategory", width: 110 },
        { field: "manufacturerName", headerName: "Manufacturer", width: 110 },

        // { field: "categoryID", headerName: "Category", width: 150 },
        // { field: "subcategoryID", headerName: "Subcategory", width: 150 },
        // { field: "manufacturerID", headerName: "Manufacturer", width: 150 },

        { field: "modelNumber", headerName: "Model Number", width: 150 },
        { field: "productName", headerName: "Product Name", width: 150 },
        { field: "serialNumber", headerName: "Serial Number", width: 150 },

        // { field: "notes", headerName: "Notes", width: 150 },
        // { field: "statusName", headerName: "Status", width: 150 },
        { field: "orgName", headerName: "School", width: 150 },
        { field: "locName", headerName: "Location", width: 150 },
    ];

    const columnsHistory: GridColDef[] = [
        // { field: "id", headerName: "ID", width: 20 },

        {
            field: "changeDate",
            headerName: "Date",
            width: 150,
            renderCell: (params) => (
                <Chip
                    label={moment(params.value).format("DD-MM-YYYY")}
                    sx={{ width: "100%", borderRadius: 2 }}
                />
            ),
        },

        // {
        //     field: "actionName",
        //     headerName: "Action",
        //     width: 150,
        // },

        // { field: "actionColor", headerName: "", width: 10 , renderCell: (params) => (
        //     //if actionColor is not null, then render a chip with the color of the previous field
        //     params.value !== null ? <Chip label={""} sx={{backgroundColor: params.value}} /> : null
        // )},

        //compined field from two fields
        {
            field: "actionLabel",
            headerName: "Action",
            width: 160,
            renderCell: (params) =>
                //if actionColor is not null, then render a chip sqare corners and with same width and with the color of the previous field
                params.row.actionColor !== null ? (
                    <Chip
                        label={params.value}
                        sx={{
                            backgroundColor: params.row.actionColor,
                            width: "100%",
                            borderRadius: 2,
                        }}
                    />
                ) : null,
        },

        { field: "details1", headerName: "Details 1", width: 170 },
        { field: "details2", headerName: "Details 2", width: 170 },
        { field: "details3", headerName: "Details 3", width: 170 },

        //{ field: "accountEmail", headerName: "Account", width: 250 },
        //field accountEmail as chip with smaller font size
        {
            field: "accountEmail",
            headerName: "Account",
            width: 250,
            renderCell: (params) => (
                <Chip
                    label={params.value}
                    sx={{ width: "100%", borderRadius: 2, fontSize: 10 }}
                />
            ),
        },

        // { field: "actionTypeID", headerName: "Action ID", width: 110 },
        // { field: "actionName", headerName: "Action", width: 110 },
        // { field: "notes", headerName: "Notes", width: 150 },
    ];

    //toDelete
    // const columnPurchases: GridColDef[] = [
    //     // { field: "purchaseID", headerName: "ID", width: 0 },
    //     { field: "SupplierName", headerName: "Supplier", width: 200 },
    //     { field: "purchaseDate", headerName: "Inv.Date", width: 100, valueFormatter: (params) => (params?.value !== "" ? moment(params?.value).format("DD/MM/YYYY") : "") },
    //     { field: "invoiceNrExt", headerName: "#Ext", width: 100 },
    //     { field: "invoiceNrInt", headerName: "#Int", width: 100 },
    //     { field: "invoiceNrSch", headerName: "#Sch", width: 100 },

    //     {
    //         field: "NumberOfFiles",
    //         headerName: "Files",
    //         width: 150,
    //         renderCell: (params) => {
    //             if (params.row.fileID !== null) {
    //                 return (

    //                     <Tooltip title={params.row.fileName} placement="top" key={params.row.fileID}>
    //                         <Link href={"https://drive.google.com/file/d/" + params.row.fileID + "/view"} target="_blank" rel="noreferrer">
    //                             <FileOpenIcon />
    //                         </Link>
    //                     </Tooltip>
    //                 );
    //             } else {
    //                 return (
    //                     <>
    //                         <Tooltip title="No files" placement="top">
    //                             <Chip label="No files" sx={{ width: "100%", borderRadius: 2 }} />
    //                         </Tooltip>
    //                     </>
    //                 );
    //             }
    //         },
    //     },
    //     { field: "notes", headerName: "Notes", width: 200 },
    //     // { field: "URLfile", headerName: "URLfile", width: 150 },
    //     // { field: "Address", headerName: "Address", width: 150 },
    //     { field: "ContactInfo", headerName: "ContactInfo", width: 150 },
    // ];

    const columnsFiles: GridColDef[] = [
        // { field: "id", headerName: "ID", width: 0 },
        {
            field: "webViewLink",
            headerName: "Open",
            width: 50,
            renderCell: (params) => (
                <Tooltip title="Open file in new tab" placement="top">
                    <Link
                        href={
                            "https://drive.google.com/file/d/" +
                            params.id +
                            "/view"
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FileOpenIcon />
                    </Link>
                </Tooltip>
            ),
        },
        {
            field: "FType",
            headerName: "Type",
            width: 80,
            renderCell: (params) => (
                //typography using MimeTypeDictionary
                <Typography variant="body2" color="text.secondary">
                    {MimeTypeDictionary[params.row.mimeType]}
                </Typography>
            ),
        },
        { field: "name", headerName: "Name", width: 450 },
        { field: "purchaseID", headerName: "Purchase ID", width: 10 },

        //if field purchaseDate is not "" then format DD/MM/YYYY
        {
            field: "purchaseDate",
            headerName: "Invoice date",
            width: 100,
            valueFormatter: (params) =>
                params?.value !== ""
                    ? moment(params?.value).format("DD/MM/YYYY")
                    : "",
        },

        { field: "invoiceNrInt", headerName: "Invoice Nr", width: 150 },
        { field: "SupplierName", headerName: "Supplier", width: 150 },
        { field: "productCount", headerName: "Products", width: 100 },

        // { field: "mimeType", headerName: "Mime Type", width: 250 },

        // { field: "driveId", headerName: "Drive ID", width: 250 },
        // { field: "teamDriveId", headerName: "Team Drive ID", width: 250 },
        // { field: "kind", headerName: "Kind", width: 150 },
        // icon column with link to open the file in new tab
    ];

    useEffect(() => {
        async function schoolsData() {
            try {
                const response = await axios.get(
                    hostsConfig.BACKEND_HOST + "/organisationstree"
                );
                console.log(response);

                let schools = response.data;
                for (let i = 0; i < schools.length; i++) {
                    if (schools[i].locations.length > 0) {
                        for (let j = 0; j < schools[i].locations.length; j++) {
                            if (schools[i].locations[j].id === null) {
                                //remove this location
                                schools[i].locations.splice(j, 1);
                            }
                        }
                    }
                }

                //rename location.description to location.title
                for (let i = 0; i < schools.length; i++) {
                    if (schools[i].locations.length > 0) {
                        for (let j = 0; j < schools[i].locations.length; j++) {
                            schools[i].locations[j].title =
                                schools[i].locations[j].description;
                        }
                    }
                }

                //sort locations by field 'desc'
                for (let i = 0; i < schools.length; i++) {
                    if (schools[i].locations.length > 0) {
                        schools[i].locations.sort((a, b) =>
                            a.title > b.title ? 1 : -1
                        );
                    }
                }

                setSchools(schools);
            } catch (error) {
                console.error(error);
            }
        }
        schoolsData();

        // if (window.location.pathname.includes("/product/") || session.goback.page === "editProduct") {
        if (window.location.pathname.includes("/editproduct/")) {
            let productID = "";

            if (window.location.pathname.includes("/editproduct/")) {
                productID = window.location.pathname.replace(
                    "/editproduct/",
                    ""
                );
            }

            // if (session.goback.page === "editProduct") {
            //     productID = session.goback.id;
            // }

            //validate if productID is a GUID
            if (productID.length !== 36) {
                console.log("productID is not a GUID");
                setMessage("Product ID is not a correct");
                setSeverity("error");
                setOpenMessage(true);
                return;
            }

            // setShowProductDetails(true);

            try {
                setSelectedProduct(productID);
                // setShowFilters(false);
                async function getHistoryByProduct() {
                    try {
                        // const response = await axios.get(
                        //     hostsConfig.BACKEND_HOST +
                        //     `/history_by_product?productID=${productID}`
                        // );
                        // console.log(response.data.productHistory);
                        // setProductHistory(response.data.productHistory);
                        // setProductDetailsObj(response.data.productDetails[0]);
                        // setProductLastStatusObj(response.data.productLastStatus[0]);
                        // setProductLastPurchaseObj(response.data.productLastPurchase[0]);
                        // setProductLastLocationObj(response.data.productLastLocation[0]);

                        // init_purchase_info(response.data.productLastPurchase[0]);

                        setActiveStep(2);
                        setFiltersExplanded(false);

// =================== 

                        const response = await axios.get(
                            hostsConfig.BACKEND_HOST +
                            `/history_by_product?productID=${productID}`
                        );
                        console.log(response.data.productHistory);
                        setProductHistory(response.data.productHistory);
                        setProductDetailsObj(response.data.productDetails[0]);
                        setProductLastStatusObj(response.data.productLastStatus[0]);
                        setProductLastPurchaseObj(response.data.productLastPurchase[0]);
                        setProductLastLocationObj(response.data.productLastLocation[0]                    );

                        init_purchase_info(response.data.productLastPurchase[0]);

                        if (
                            response.data.productLastLocation[0] !== undefined &&
                            response.data.productLastLocation[0].notes !== null
                        ) {
                            setLocationNotes(
                                response.data.productLastLocation[0].notes
                            );
                        } else {
                            setLocationNotes("");
                        }

                        setSelectedRows([productID]);
                        setSelectedFiles([]);
                        setSession({ ...session, selectedProducts: [productID] });

// =================== 

                    } catch (error) {
                        console.error(error);
                    }
                }
                getHistoryByProduct();
            } catch (error) {
                console.log(error);
            }
        } else {
            console.log("no productID");
        }


        // async function getProductWithoutSchool() {
        //     try {
        //         const response = await axios.get(hostsConfig.BACKEND_HOST + "/products_no_org");
        //         console.log(response);
        //         setProducts(response.data.products);
        //     } catch (error) {
        //         console.error(error);
        //     }
        // }
        // if (selectedSchool === "") {
        //     getProductWithoutSchool();
        // } else {
        //     refreshProducts();
        // }

        let steps = [
            {
                id: 1,
                label: "Filter products",
                hasNext: true,
                previouseButtonDisabled: true,
                nextButtonDisabled: false,
                completedButtonVisible: false,
                skipped: false,
                skippedButtonDisabled: false,
                skippedButtonLabel: "Skip",
                nextButtonLabel: "Volgende",
                previousButtonLabel: "Vorige",
            },
            {
                id: 2,
                label: "Overview/edit details",
                hasNext: true,
                previouseButtonDisabled: false,
                nextButtonDisabled: false,
                completedButtonVisible: true,
                skipped: false,
                skippedButtonDisabled: false,
                skippedButtonLabel: "Skip",
                nextButtonLabel: "Volgende",
                previousButtonLabel: "Vorige",
            },
            {
                id: 3,
                label: "Invoices",
                hasNext: false,
                previouseButtonDisabled: false,
                nextButtonDisabled: true,
                completedButtonVisible: false,
                skipped: false,
                skippedButtonDisabled: false,
                skippedButtonLabel: "Skip",
                nextButtonLabel: "Volgende",
                previousButtonLabel: "Vorige",
            },
            // {
            //     id: 4,
            //     label: "Existing invoice",
            //     hasNext: false,
            //     previouseButtonDisabled: false,
            //     nextButtonDisabled: true,
            //     completedButtonVisible: false,
            //     skipped: false,
            //     skippedButtonDisabled: false,
            //     skippedButtonLabel: "Skip",
            //     nextButtonLabel: "Volgende",
            //     previousButtonLabel: "Vorige",
            // },
        ];

        setSteps(steps);

        //11/17
        // async function purchasesData() {
        //     try {
        //         const response = await axios.get(hostsConfig.BACKEND_HOST + "/purchases_by_filters/null/null/null/null/null/null/null");
        //         console.log(response.data.purchases);

        //         let purchases = response.data.purchases;

        //         //for every purchase, get the purshaseID and add it to the array as "id" also
        //         for (let i = 0; i < purchases.length; i++) {
        //             purchases[i].id = purchases[i].purchaseID;
        //         }

        //         setPurchases(response.data.purchases);
        //         // setAllPurchases(response.data.purchases);
        //     } catch (error) {
        //         console.error(error);
        //     }
        // }
        // purchasesData();

        if (session.environment !== undefined) {
            if (session.environment.drives.length === 1) {
                setDriveDropdown(session.environment.drives[0].driveGUID);
            }
        }
    }, []);


    useEffect(() => {
        if (schools.length > 0) {

            if (session?.goback?.page !== undefined && session.goback.page === "editProduct") {
                let productID = session.goback.id;
                let queryString = session.goback.queryString;

                // SchoolDropdown=593add8f-ca45-11ed-b582-bc8385f61308&LocationDropdown=47d9bc96-8df1-11ee-a1aa-0242c0a8a002&StatusDropdown=&ActionTypeDropdown=&Email=&CategoryDropdown=&SubcategoryDropdown=&ManufacturerDropdown=&ModelNumber=&ProductName=&SerialNumber=&PurchaseDateFrom=&PurchaseDateTo=&SupplierDropdown=&PriceVATinclFrom=&PriceVATinclTo=&InvoiceNrExt=&InvoiceNrInt=&InvoiceNrSch=

                // let queryString = `SchoolDropdown=${selectedSchool}&LocationDropdown=${selectedLocation}&StatusDropdown=${selectedStatus}&ActionTypeDropdown=${selectedActionType}&Email=${email}&CategoryDropdown=${selectedCategory}&SubcategoryDropdown=${selectedSubcategory}&ManufacturerDropdown=${selectedManufacturer}&ModelNumber=${modelNumber}&ProductName=${productName}&SerialNumber=${serialNumber}&PurchaseDateFrom=${purchaseDateFrom}&PurchaseDateTo=${purchaseDateTo}&SupplierDropdown=${selectedSupplier}&PriceVATinclFrom=${priceVATinclFrom}&PriceVATinclTo=${priceVATinclTo}&InvoiceNrExt=${invoiceNrExt}&InvoiceNrInt=${invoiceNrInt}&InvoiceNrSch=${invoiceNrSch}`;
                // brake down the queryString into the folloing variables :
                // schoolDropdown
                // locationDropdown
                // categoryDropdown
                // subcategoryDropdown
                // statusDropdown
                // actionTypeDropdown
                // email
                // manufacturerDropdown
                // modelNumber
                // productName
                // serialNumber
                // purchaseDateFrom
                // purchaseDateTo
                // supplierDropdown
                // priceVATinclFrom
                // invoiceNrExt
                // invoiceNrInt
                // invoiceNrSch
                // priceVATinclTo

                let queryStringArray = queryString.split("&");
                setSchoolDropdown(queryStringArray[0].split("=")[1]);

                if (queryStringArray[1].split("=")[1] !== "") {
                    let sch_id = schools.filter((school) => school.id === queryStringArray[0].split("=")[1])[0].id
                    let sch_obj = schools.filter((school) => school.id === queryStringArray[0].split("=")[1])[0]

                    setSelectedSchool(schools.filter((school) => school.id === queryStringArray[0].split("=")[1])[0].id);
                    setSelectedSchoolObj(schools.filter((school) => school.id === queryStringArray[0].split("=")[1])[0]);
                }

                setLocationDropdown(queryStringArray[1].split("=")[1]);
                setStatusDropdown(queryStringArray[2].split("=")[1]);
                setActionTypeDropdown(queryStringArray[3].split("=")[1]);
                setEmail(queryStringArray[4].split("=")[1]);
                setCategoryDropdown(queryStringArray[5].split("=")[1]);
                setSubcategoryDropdown(queryStringArray[6].split("=")[1]);
                setManufacturerDropdown(queryStringArray[7].split("=")[1]);
                setModelNumber(queryStringArray[8].split("=")[1]);
                setProductName(queryStringArray[9].split("=")[1]);
                setSerialNumber(queryStringArray[10].split("=")[1]);
                setPurchaseDateFrom(queryStringArray[11].split("=")[1]);
                setPurchaseDateTo(queryStringArray[12].split("=")[1]);
                setSupplierDropdown(queryStringArray[13].split("=")[1]);
                setPriceVATinclFrom(queryStringArray[14].split("=")[1]);

                setLoading(true);

                async function fetchProducts() {
                    try {
                        const response = await axios.get(
                            hostsConfig.BACKEND_HOST +
                            `/filter_products?${queryString}`
                        );
                        console.log(hostsConfig.BACKEND_HOST + "/filter_products");
                        console.log(response);

                        setProducts(response.data.products);

                        if (
                            productID &&
                            productID !== "null" &&
                            productID !== "undefined" &&
                            productID !== ""
                        ) {
                            setSelectedProduct(productID);
                        }
                    } catch (error) {
                        console.error(error);
                    }
                }
                fetchProducts();

                let newSession = session;
                newSession = {
                    ...newSession,
                    goback: {
                        page: "",
                        id: null,
                        queryString: "",
                    }
                }

                setSession(newSession);

                setLoading(false);
            }
        }
    }, [schools]);

    //every time productDetailsObj changes, then set the values of the productDetailsObj to the state variables
    useEffect(() => {
        // if productDetailsObj is not null, then set the values of the productDetailsObj to the state variables
        if (productDetailsObj !== undefined) {
            setProductSchool(
                productDetailsObj.currentOrganisationID !== null
                    ? productDetailsObj.currentOrganisationID
                    : ""
            );
            setProductLocation(
                productDetailsObj.currentLocationID !== null
                    ? productDetailsObj.currentLocationID
                    : ""
            );

            if (productLastStatusObj !== undefined) {
                setProductStatus(
                    productDetailsObj.currentStatusID !== null
                        ? productDetailsObj.currentStatusID
                        : ""
                );
            } else {
                setProductStatus("");
            }

            //setProductStatusNotes(productDetailsObj.statusNotes !== undefined ? productDetailsObj.statusNotes : "");
            //setProductHistoryNotes(productDetailsObj.historyNotes !== undefined ? productDetailsObj.historyNotes : "");
            setProductCategory(
                productDetailsObj.categoryID !== null
                    ? productDetailsObj.categoryID
                    : ""
            );
            setProductSubcategory(
                productDetailsObj.subcategoryID !== null
                    ? productDetailsObj.subcategoryID
                    : ""
            );
            setProductManufacturer(
                productDetailsObj.manufacturerID !== null
                    ? productDetailsObj.manufacturerID
                    : ""
            );
            setProductModelNumber(
                productDetailsObj.modelNumber !== null
                    ? productDetailsObj.modelNumber
                    : ""
            );
            setProductProductName(
                productDetailsObj.productName !== null
                    ? productDetailsObj.productName
                    : ""
            );
            setProductSerialNumber(
                productDetailsObj.serialNumber !== null
                    ? productDetailsObj.serialNumber
                    : ""
            );
            setProductDetailNotes(
                productDetailsObj.notes !== null ? productDetailsObj.notes : ""
            );
            setProductInsurance(
                productDetailsObj.insuranceID !== null
                    ? productDetailsObj.insuranceID
                    : ""
            );
        } else {
            setProductSchool("");
            setProductLocation("");
            setProductNotes("");

            setProductStatus("");

            //setProductStatusNotes("");
            //setProductHistoryNotes("");
            setProductCategory("");
            setProductSubcategory("");
            setProductManufacturer("");
            setProductModelNumber("");
            setProductProductName("");
            setProductSerialNumber("");
            setProductDetailNotes("");
            setProductInsurance("");
        }
    }, [productDetailsObj]);

    //every time productLastStatusObj changes, then set the values of the productLastStatusObj to the state variables
    useEffect(() => {
        // if productLastStatusObj is not null, then set the values of the productLastStatusObj to the state variables

        if (productLastStatusObj !== undefined) {
            //if productDetailsObj is not null, then set the values of the productDetailsObj to the state variables
            if (productDetailsObj !== undefined) {
                // setProductStatusNotes(productLastStatusObj.statusNotes !== undefined ? productLastStatusObj.statusNotes : "");
                setProductHistoryNotes(
                    productLastStatusObj.historyNotes !== undefined &&
                        productLastStatusObj.historyNotes !== null
                        ? productLastStatusObj.historyNotes
                        : ""
                );
            }
        } else {
            // setProductStatusNotes("");
            setProductHistoryNotes("");
        }
    }, [productLastStatusObj]);

    //useEffect when manufacturersChanged is changed
    useEffect(() => {
        var env = {};
        var _users = [];

        async function environmentData() {
            try {
                const response = await axios.get(
                    hostsConfig.BACKEND_HOST + "/environment"
                );
                console.log(response);
                env = response.data;
                //setSession({ ...session, environment: response.data });
            } catch (error) {
                console.error(error);
            }
        }
        //environmentData();

        async function usersData() {
            try {
                const response = await axios.get(
                    hostsConfig.BACKEND_HOST + "/users"
                );
                console.log(response);
                //add users to session.environment
                _users = response.data.users;
                //setSession({ ...session, users: response.data.users });
            } catch (error) {
                console.error(error);
            }
        }
        //usersData();

        //exequte check_if_has_token , environmentData , usersData one after the other
        Promise.all([environmentData(), usersData()]).then((values) => {
            console.log("check_if_has_token , environmentData , usersData one");
            setSession({ ...session, environment: env, users: _users });
        });
    }, [manufacturersChanged]);

    // const handleCloseDialog = () => {
    //     setOpenDialog(false);
    //     setDialogAction("");
    //     setDialogTitle("");
    //     setDialogMessage("");
    // };

    // const handleConfirmDialog = () => {
    //     setOpenDialog(false);
    //     switch (dialogAction) {
    //         case "blablabla":
    //             break;
    //         default:
    //             break;
    //     }
    // };

    const handleSelectedSupplierChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
        setSupplierDropdown(event.target.value);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handleApplyFilters = async () => {
        setLoading(true);

        var msg = "";

        //check purchaseDateFrom and purchaseDateTo are valid dates
        let purchaseDateFromValid = true;
        let purchaseDateToValid = true;
        if (purchaseDateFrom !== "") {
            purchaseDateFromValid = moment(
                purchaseDateFrom,
                "YYYY-MM-DD",
                true
            ).isValid();
        }
        if (purchaseDateFromValid === false) {
            msg = msg + "Invoice Date From is not a valid date.";
        }

        if (purchaseDateTo !== "") {
            purchaseDateToValid = moment(
                purchaseDateTo,
                "YYYY-MM-DD",
                true
            ).isValid();
        }
        if (purchaseDateToValid === false) {
            msg = msg + "Invoice Date To is not a valid date.";
        }

        //check priceVATinclFrom and priceVATinclTo are valid numbers
        let priceVATinclFromValid = true;
        let priceVATinclToValid = true;

        if (priceVATinclFrom !== "") {
            priceVATinclFromValid = !isNaN(Number(priceVATinclFrom));
        }
        if (priceVATinclFromValid === false) {
            msg = msg + "Price VAT incl From is not a valid number.";
        }

        if (priceVATinclTo !== "") {
            priceVATinclToValid = !isNaN(Number(priceVATinclTo));
        }
        if (priceVATinclToValid === false) {
            msg = msg + "Price VAT incl To is not a valid number.";
        }

        //check purchaseDateFrom is before purchaseDateTo
        if (purchaseDateFrom !== "" && purchaseDateTo !== "") {
            if (moment(purchaseDateFrom).isAfter(moment(purchaseDateTo))) {
                msg = msg + "Invoice Date From is after Invoice Date To.";
            }
        }
        //check priceVATinclFrom is biger than priceVATinclTo
        if (priceVATinclFrom !== "" && priceVATinclTo !== "") {
            if (Number(priceVATinclFrom) > Number(priceVATinclTo)) {
                msg =
                    msg +
                    "Price VAT incl From is bigger than Price VAT incl To.";
            }
        }

        if (msg !== "") {
            setMessage(msg);
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        // setShowProductDetails(false);

        // SchoolDropdown;
        // LocationDropdown;
        // StatusDropdown;
        // ActionTypeDropdown;
        // Email;
        // CategoryDropdown;
        // SubcategoryDropdown;
        // ManufacturerDropdown;
        // ModelNumber;
        // ProductName;
        // SerialNumber;
        // PurchaseDateFrom;
        // PurchaseDateTo;
        // FilterSupplier;
        // PriceVATinclFrom;
        // PriceVATinclTo;
        // InvoiceNrExt;
        // InvoiceNrInt;
        // InvoiceNrSch;

        try {
            let body = {
                SchoolDropdown: schoolDropdown,
                LocationDropdown: locationDropdown,
                StatusDropdown: statusDropdown,
                ActionTypeDropdown: actionTypeDropdown,
                Email: email,
                CategoryDropdown: categoryDropdown,
                SubcategoryDropdown: subcategoryDropdown,
                ManufacturerDropdown: manufacturerDropdown,
                ModelNumber: modelNumber,
                ProductName: productName,
                SerialNumber: serialNumber,
                PurchaseDateFrom: purchaseDateFrom,
                PurchaseDateTo: purchaseDateTo,
                SupplierDropdown: supplierDropdown,
                PriceVATinclFrom: priceVATinclFrom,
                PriceVATinclTo: priceVATinclTo,
                InvoiceNrExt: invoiceNrExt,
                InvoiceNrInt: invoiceNrInt,
                InvoiceNrSch: invoiceNrSch,
            };

            // Convert the filters object into a query string
            const queryString = Object.keys(body)
                .map(
                    (key) =>
                        `${encodeURIComponent(key)}=${encodeURIComponent(
                            body[key]
                        )}`
                )
                .join("&");

            const response = await axios.get(
                hostsConfig.BACKEND_HOST + `/filter_products?${queryString}`
            );
            console.log(hostsConfig.BACKEND_HOST + "/filter_products");
            console.log(response);

            setSession({
                ...session,
                goback: {
                    page: "editProduct",
                    id: null,
                    queryString: queryString,
                },
            });

            setProducts(response.data.products);
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    const handleClearFilters = async () => {
        setSchoolDropdown("");
        setSelectedSchool("");
        setSelectedSchoolObj(null);

        setLocationDropdown("");
        setStatusDropdown("");
        setActionTypeDropdown("");
        setCategoryDropdown("");
        setSubcategoryDropdown("");

        setManufacturerDropdown("");
        setModelNumber("");
        setProductName("");
        setSerialNumber("");
        setPurchaseDateFrom("");
        setPurchaseDateTo("");
        setSupplierDropdown("");
        setPriceVATinclFrom("");
        setPriceVATinclTo("");
        setInvoiceNrExt("");
        setInvoiceNrInt("");
        setInvoiceNrSch("");

        setProducts([]);
        setProductHistory([]);
        setProductDetailsObj();
    };

    const handleRowClick: GridEventListener<"rowClick"> = async (params) => {
        console.log(params);
        setSelectedProduct(params.id);

        try {
            async function getHistoryByProduct() {
                try {
                    const response = await axios.get(
                        hostsConfig.BACKEND_HOST +
                        `/history_by_product?productID=${params.id}`
                    );
                    console.log(response.data.productHistory);
                    setProductHistory(response.data.productHistory);
                    setProductDetailsObj(response.data.productDetails[0]);
                    setProductLastStatusObj(response.data.productLastStatus[0]);
                    setProductLastPurchaseObj(
                        response.data.productLastPurchase[0]
                    );
                    setProductLastLocationObj(
                        response.data.productLastLocation[0]
                    );

                    init_purchase_info(response.data.productLastPurchase[0]);

                    if (
                        response.data.productLastLocation[0] !== undefined &&
                        response.data.productLastLocation[0].notes !== null
                    ) {
                        setLocationNotes(
                            response.data.productLastLocation[0].notes
                        );
                    } else {
                        setLocationNotes("");
                    }

                    setSelectedRows([params.id]);
                    setSelectedFiles([]);
                    setSession({ ...session, selectedProducts: [params.id] });
                } catch (error) {
                    console.error(error);
                }
            }
            getHistoryByProduct();
        } catch (error) {
            console.log(error);
        }
    };

    const init_purchase_info = (productLastPurchase) => {

        if (productLastPurchase !== undefined) {
            setPriceVATexcl(
                productLastPurchase
                    .priceVATexcl !== null
                    ? productLastPurchase
                        .priceVATexcl
                    : ""
            );
            setVAT(
                productLastPurchase.VAT !== null
                    ? productLastPurchase.VAT
                    : ""
            );
            setPriceVATincl(
                productLastPurchase
                    .priceVATincl !== null
                    ? productLastPurchase
                        .priceVATincl
                    : ""
            );

            setCountConnectedProducts(productLastPurchase.productCount)

            setAddSavePurchaseButtonLabel("Save changes");
            setDisableAddPurchase(false);
            setDisableChangePurchase(true);

            setExistingPurchase(productLastPurchase);

            setNewSupplierID(productLastPurchase.supplierID);
            let supplier = session.environment.suppliers.filter(
                (supplier) => supplier.SupplierID === productLastPurchase.supplierID
            )[0];
            setOptionSupplier(supplier.SupplierName);

            let file = {
                id: productLastPurchase.fileID,
                name: productLastPurchase.fileName,
            };
            setNewURLFiles([file]);
            setIsFileConnected(file.id !== null);
            setNewPurchaseDate(
                moment(
                    productLastPurchase
                        .purchaseDate
                ).format("YYYY-MM-DD")
            );
            
            setInvoicesSelectedPurchaseID(
                productLastPurchase.purchaseID
            );

            setNewInvoiceNrInt(
                productLastPurchase
                    .invoiceNrInt === null
                    ? ""
                    : productLastPurchase
                        .invoiceNrInt
            );

            setNewPurchaseNotes(
                productLastPurchase
                    .purchaseNotes === null
                    ? ""
                    : productLastPurchase
                        .purchaseNotes
            );
            
            setDisableIsFileConnected(true);
            setDisableCanRemoveFile(true);
            setDisableSupplier(true);
            setDisablePurchaseDate(true);
            setDisableInvoiceNrInt(true);
            setDisablePurchaseNotes(true);

        } else {
            setPriceVATexcl("");
            setVAT("");
            setPriceVATincl("");
            setCountConnectedProducts(0);
            setIsFileConnected(true);
            setNewSupplierID("");
            setOptionSupplier("");
            setNewURLFiles([]);
            setNewPurchaseDate("");
            setInvoicesSelectedPurchaseID("");
            setNewInvoiceNrInt("");
            setNewPurchaseNotes("");
            setExistingPurchase(null);
            setAddSavePurchaseButtonLabel("Create new invoice");
            setDisableAddPurchase(false);
            setDisableChangePurchase(true);

            setDisableIsFileConnected(false);
            setDisableCanRemoveFile(false);
            setDisableSupplier(false);
            setDisablePurchaseDate(false);
            setDisableInvoiceNrInt(false);
            setDisablePurchaseNotes(false);
        }

    }

    const handleRowClick_multiselect: GridEventListener<"rowClick"> = async (
        params
    ) => { };

    const handleSelectionModelChange: GridEventListener<"selectionChange"> = (
        ids
    ) => {
        try {
            setSelectedRows(ids);
            setSession({ ...session, selectedProducts: ids });
        } catch (e) {
            console.log(e);
        }
    };

    const handleSelectedSchoolChange = async (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        try {
            setSchoolDropdown(event.target.value);
            // setSchoolGrid(event.target.value);
            setSelectedSchool(event.target.value);
            let sch = schools.filter(
                (school) => school.id === event.target.value
            )[0];
            setSelectedSchoolObj(sch);
            // setEditSchoolName(sch.name);
            // setEditSchoolNameShort(sch.nameShort);
            // setEditSchoolCodeExternal(sch.codeExternal);
            // setEditSchoolCodeInternal(sch.codeInternal);
            // setEditSchoolDomain(sch.domain);
            // let q = hostsConfig.BACKEND_HOST + "/products_org_loc/" + event.target.value;
            // async function getProductBySchool() {
            //     try {
            //         const response = await axios.get(hostsConfig.BACKEND_HOST + "/products_org_loc/" + event.target.value);
            //         console.log(response);
            //         setProducts(response.data.products);
            //     } catch (error) {
            //         console.error(error);
            //     }
            // }
            // getProductBySchool();
            // setSelectedLocation("");
            // setLocationDropdown("");
            // setLocationGrid("");
            // setSelectedProduct(null);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSelectedLocationChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setLocationDropdown(event.target.value);
    };

    const handleSelectedStatusChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setStatusDropdown(event.target.value);
    };

    const handleSelectedActionTypeChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setActionTypeDropdown(event.target.value);
    };

    const handleSelectedCategoryChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setCategoryDropdown(event.target.value);
    };

    const handleSelectedSubcategoryChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setSubcategoryDropdown(event.target.value);
    };

    const handleSelectedManufacturerChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setManufacturerDropdown(event.target.value);
    };

    const handleModelNrChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setModelNumber(event.target.value);
    };

    const handleProductNameChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setProductName(event.target.value);
    };

    const handleSerialNumberChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSerialNumber(event.target.value);
    };

    const handlePurchaseDateFromChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setPurchaseDateFrom(event.target.value);
    };

    const handlePurchaseDateToChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setPurchaseDateTo(event.target.value);
    };

    const handlePriceVATinclFromChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setPriceVATinclFrom(event.target.value);
    };

    const handlePriceVATinclToChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setPriceVATinclTo(event.target.value);
    };

    const handleInvoiceNrExtChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setInvoiceNrExt(event.target.value);
    };

    const handleInvoiceNrIntChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setInvoiceNrInt(event.target.value);
    };

    const handleInvoiceNrSchChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setInvoiceNrSch(event.target.value);
    };

    const handleChangeProductsLocation = async () => {
        let count_success = 0;
        let count_error = 0;

        for (let i = 0; i < selectedRows.length; i++) {
            let ID = selectedRows[i];

            if (session.environment === undefined) {
                return;
            }

            //get the actionTypeID from the environment.actionTypes array of objects where the actionName is "Relocate"
            let actionTypeID = session.environment.actiontype.find(
                (x) => x.actionName === "Relocate"
            ).actionTypeID;
            let accountEmail = auth?.userInfo?.email || "";

            try {
                let body = {
                    productID: selectedRows[i],
                    organisationID: productSchool,
                    locationID: productLocation,
                    inputLocation: productInputLocation,
                    actionTypeID: actionTypeID,
                    accountEmail: accountEmail,
                    notes: locationNotes,
                };

                const response = await axios.post(
                    hostsConfig.BACKEND_HOST +
                    "/changeProductOrganisationLocationInputLocation",
                    body
                );
                // const response = ""

                count_success++;

                setProductSchool(
                    response.data.products[0].organisationID !== null
                        ? response.data.products[0].organisationID
                        : ""
                );
                setProductLocation(
                    response.data.products[0].locationID !== null
                        ? productDetailsObj.locationID
                        : ""
                );

                console.log(response);
            } catch (error) {
                console.error(error);
            }
        }

        if (selectedRows.length === 1) {
            handleRowClick({ id: selectedRows[0] });
        }

        if (selectedRows.length === count_success) {
            setMessage(selectedRows.length + " relocated succesfuly.");
            setSeverity("success");
            setOpenMessage(true);
        } else {
            if (count_success > 0) {
                setMessage(
                    "Only " + numberOfProducts + " relocated succesfuly."
                );
                setSeverity("warning");
                setOpenMessage(true);
            } else {
                setMessage("No product relocated.");
                setSeverity("error");
                setOpenMessage(true);
            }
        }
    };

    const handleChangeProductsStatus = async () => {
        let count_success = 0;
        let count_error = 0;

        for (let i = 0; i < selectedRows.length; i++) {
            let ID = selectedRows[i];

            if (session.environment === undefined) {
                return;
            }

            //get the actionTypeID from the environment.actionTypes array of objects where the actionName is "Relocate"
            let actionTypeID = session.environment.actiontype.find(
                (x) => x.actionName === "Status change"
            ).actionTypeID;
            let accountEmail = auth?.userInfo?.email || "";

            try {
                let body = {
                    productID: ID,
                    statusID: productStatus,
                    actionTypeID: actionTypeID,
                    accountEmail: accountEmail,
                    historyNotes: productHistoryNotes,
                    // statusNotes: newStatusNotes,
                };

                const response = await axios.post(
                    hostsConfig.BACKEND_HOST + "/changeProductStatus",
                    body
                );

                count_success++;

                console.log(response);
            } catch (error) {
                console.error(error);
            }
        }

        if (selectedRows.length === 1) {
            handleRowClick({ id: selectedRows[0] });
        }

        if (selectedRows.length === count_success) {
            setMessage(selectedRows.length + " status changed succesfuly.");
            setSeverity("success");
            setOpenMessage(true);
        } else {
            if (count_success > 0) {
                setMessage(
                    "Only " + numberOfProducts + " status changed succesfuly."
                );
                setSeverity("warning");
                setOpenMessage(true);
            } else {
                setMessage("No status changed.");
                setSeverity("error");
                setOpenMessage(true);
            }
        }
    };

    const handleDialogDeleteProduct = () => {
        setDialogFunction("deleteProduct");
        setOpenDialog(true);
        //setMessage("Are you sure you want to create this product?");
        setDialogTitle("Dalete product");
        //Are you sure you want to create 'numberProducts' products?"
        setDialogMessage("Are you sure you want to delete this product?");
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setDialogFunction("");
        setDialogAction("");
        setDialogTitle("");
        setDialogMessage("");
    };

    const handleConfirmDialog = () => {
        if(dialogFunction === "deleteProduct") {
        setOpenDialog(false);
        setDialogFunction("");
        handleDeleteProduct();
        }
        if(dialogFunction === "saveInvoice") {
            setOpenDialog(false);
            setDialogFunction("");
            handleAddNewPurshase();
        }
    };


    const handleDeleteProduct = async () => {
        let count_success = 0;
        let count_error = 0;

        for (let i = 0; i < selectedRows.length; i++) {
            let ID = selectedRows[i];

            if (session.environment === undefined) {
                return;
            }

            let accountEmail = auth?.userInfo?.email || "";

            try {
                let body = {
                    productID: ID,
                    accountEmail: accountEmail,
                };

                const response = await axios.post(
                    hostsConfig.BACKEND_HOST + "/deleteProduct",
                    body
                );

                count_success++;

                console.log(response);
            } catch (error) {
                console.error(error);
            }
        }

        // if (selectedRows.length === 1) {
        //     handleRowClick({ id: selectedRows[0] });
        // }

        if (selectedRows.length === count_success) {
            setMessage(selectedRows.length + " products deleted succesfuly.");
            setSeverity("success");

            setOpenMessage(true);

            setActiveStep(1);
            handleApplyFilters();

            /* ======= reset form ======= */
            setSelectedProduct();
            setProductDetailsObj();
            setProductHistory([]);
            setProductLastStatusObj("");
            setProductLastLocationObj();
            setPriceVATexcl("");
            setVAT("");
            setPriceVATincl("");
            setCountConnectedProducts(0);
            setExistingPurchase(null);
            setSelectedRows([]);
            setSession({ ...session, selectedProducts: [] });
            /* ======= reset form ======= */
        } else {
            if (count_success > 0) {
                setMessage(
                    "Only " + numberOfProducts + " products deleted succesfuly."
                );
                setSeverity("warning");
                setOpenMessage(true);
            } else {
                setMessage("No products deleted.");
                setSeverity("error");
                setOpenMessage(true);
            }
        }
    };

    const handleChangeProductsDetails = async () => {
        let count_success = 0;
        let count_error = 0;

        console.log(productManufacturer);
        console.log(productInputManufacturer);

        if (selectedRows.length === 1) {
            let ID = selectedRows[0];

            if (session.environment === undefined) {
                return;
            }

            let accountEmail = auth?.userInfo?.email || "";

            try {
                let body = {
                    productID: ID,
                    categoryID: productCategory,
                    subcategoryID: productSubcategory,
                    manufacturerID: productManufacturer,
                    inputManufacturer: productInputManufacturer,
                    modelNumber: productModelNumber,
                    productName: productProductName,
                    serialNumber: productSerialNumber,
                    notes: productDetailNotes,
                    insuranceID: productInsurance,
                    accountEmail: accountEmail,
                };

                const response = await axios.put(
                    hostsConfig.BACKEND_HOST + "/changeProductDetails",
                    body
                );

                //if response.data.products[0] is not undefined then
                // if response.data.manufactorers[0] is not undefined and productManufacturer is empty then
                // insert a new manufacturer in session.environment.manufacturers

                if (response.data.products[0] !== undefined) {
                    if (
                        response.data.manufacturers[0] !== undefined &&
                        productManufacturer === ""
                    ) {
                        console.log(
                            "insert new manufacturer in session.environment.manufacturers"
                        );

                        setManufacturersChanged(manufacturersChanged + 1);
                    }
                }

                count_success++;

                console.log(response);
            } catch (error) {
                console.error(error);
            }
        }

        if (selectedRows.length === 1) {
            handleRowClick({ id: selectedRows[0] });
        }

        if (selectedRows.length === count_success) {
            setMessage(selectedRows.length + " details changed succesfuly.");
            setSeverity("success");
            setOpenMessage(true);
        } else {
            if (count_success > 0) {
                setMessage(
                    "Only " + numberOfProducts + " details changed succesfuly."
                );
                setSeverity("warning");
                setOpenMessage(true);
            } else {
                setMessage("No details changed.");
                setSeverity("error");
                setOpenMessage(true);
            }
        }
    };

    //toDelete
    // const handleAddProductInPurchaseClicked = async () => {
    //     console.log(selectedPurchase);
    //     console.log(selectedRows);

    //     var oldPurchaseID = "";
    //     var purchaseNotes = "Product added in this invoice";
    //     if (productLastPurchaseObj !== undefined) {
    //         oldPurchaseID = productLastPurchaseObj.purchaseID;
    //         purchaseNotes = "Product changed in this invoice";
    //     }

    //     if (oldPurchaseID === selectedPurchase) {
    //         setMessage("You cannot change an invoice to itself");
    //         setSeverity("error");
    //         setOpenMessage(true);
    //         return;
    //     }

    //     var productsChanged = 0;
    //     var productsNotChanged = 0;

    //     for (let i = 0; i < selectedRows.length; i++) {
    //         let ID = selectedRows[i];

    //         if (session.environment === undefined) {
    //             return;
    //         }

    //         //get the actionTypeID from the environment.actionTypes array of objects where the actionNames are "Purchase" and "Purchase change"
    //         let newActionTypeID = session.environment.actiontype.find((x) => x.actionName === "Purchase").actionTypeID;
    //         let oldActionTypeID = session.environment.actiontype.find((x) => x.actionName === "Purchase changed").actionTypeID;
    //         let newPurchaseID = selectedPurchase;
    //         // let oldPurchaseID = oldPurchaseID;
    //         let accountEmail = auth?.userInfo?.email || "";

    //         try {
    //             let body = {
    //                 productID: ID,
    //                 newPurchaseID: newPurchaseID,
    //                 newActionTypeID: newActionTypeID,
    //                 oldPurchaseID: oldPurchaseID,
    //                 oldActionTypeID: oldActionTypeID,
    //                 accountEmail: accountEmail,
    //                 notes: purchaseNotes,
    //             };

    //             const response = await axios.post(hostsConfig.BACKEND_HOST + "/changeProductPurchace", body);
    //             //refreshProducts();
    //             console.log(response);

    //             productsChanged++;
    //         } catch (error) {
    //             console.error(error);
    //             productsNotChanged++;
    //         }
    //     }
    //     setMessage(productsChanged + " products changed, " + productsNotChanged + " products not changed");
    //     setSeverity("success");
    //     setOpenMessage(true);
    // };

    //toDelete
    // const handleChangePricesClicked = async () => {
    //     var notes = "Set prices";
    //     var oldPurchaseID = "";

    //     if (productLastPurchaseObj !== undefined && productLastPurchaseObj.purchaseID !== selectedPurchase) {
    //         oldPurchaseID = productLastPurchaseObj.purchaseID;
    //         notes = "Prices changed";
    //     }

    //     var productsChanged = 0;
    //     var productsNotChanged = 0;

    //     for (let i = 0; i < selectedRows.length; i++) {
    //         let ID = selectedRows[i];

    //         let product = products.find((x) => x.id === ID);
    //         if (product === undefined) {
    //             productsNotChanged++;
    //             continue;
    //         }

    //         if (session.environment === undefined) {
    //             return;
    //         }

    //         let actionTypeID = session.environment.actiontype.find((x) => x.actionName === "Prices change").actionTypeID;
    //         let accountEmail = auth?.userInfo?.email || "";

    //         let purchaseID = selectedPurchase;

    //         try {
    //             let body = {
    //                 productID: ID,
    //                 purchaseID: purchaseID,
    //                 actionTypeID: actionTypeID,
    //                 accountEmail: accountEmail,
    //                 priceVATexcl: priceVATexcl,
    //                 VAT: VAT,
    //                 priceVATincl: priceVATincl,
    //                 notes: notes,
    //             };

    //             const response = await axios.post(hostsConfig.BACKEND_HOST + "/insertProductPrices", body);

    //             if (productLastPurchaseObj !== undefined && productLastPurchaseObj.purchaseID === selectedPurchase) {
    //                 productLastPurchaseObj.priceVATexcl = priceVATexcl;
    //                 productLastPurchaseObj.VAT = VAT;
    //                 productLastPurchaseObj.priceVATincl = priceVATincl;
    //                 setProductLastPurchaseObj(productLastPurchaseObj);
    //             }

    //             //refreshProducts();
    //             console.log(response);

    //             productsChanged++;
    //         } catch (error) {
    //             console.error(error);
    //             productsNotChanged++;
    //         }
    //     }
    //     setMessage("Price  changed");
    //     setSeverity("success");
    //     setOpenMessage(true);
    // };

    const handleCreateProducts = async () => {
        console.log("handleCreateProducts");
    };

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        //setSelectedRows([]);

        setSelectedProduct();
        setProductDetailsObj();
        setProductHistory([]);
        setProductLastStatusObj("");
        setProductLastLocationObj();

        setPriceVATexcl("");
        setVAT("");
        setPriceVATincl("");
        setCountConnectedProducts(0);
        //setSelectedPurchase("");
        //setAddInPurchaseDisabled(true);
        //setChangePricesDisabled(true);

        //setWarningPurchaseExists(false);
        setExistingPurchase(null);

        setSelectedRows([]);
        setSession({ ...session, selectedProducts: [] });

        //todo clear all fields
    };

    // ========== tabs ===============================
    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }
    // ===============================================

    const handleNext = () => {
        // if (activeStep === 3) {
        //     setOpenDialog(true);
        //     //setMessage("Are you sure you want to create this product?");
        //     setDialogTitle("Edit product");
        //     //Are you sure you want to create 'numberProducts' products?"
        //     if (numberOfProducts === "1") {
        //         setDialogMessage("Are you sure you want to save changes for this product?");
        //     } else {
        //         setDialogMessage("Are you sure you want to save changes for " + numberOfProducts + " products?");
        //     }
        // } else {
        //     setActiveStep(activeStep + 1);
        // }
        setActiveStep(activeStep + 1);
    };

    //toDelete
    // const handleFrom_dateChange = (e) => {
    //     setFilterPurchaseFrom_date(e.target.value);
    // };

    //toDelete
    // const handleTo_dateChange = (e) => {
    //     setFilterPurchaseTo_date(e.target.value);
    // };

    //toDelete
    // const handleApplyFiltersPurchase = async () => {
    //     setLoading(true);

    //     var supplier = filterPurchaseSupplier === null || filterPurchaseSupplier === "" ? "null" : filterPurchaseSupplier;
    //     var from_date = filterPurchaseFrom_date === null || filterPurchaseFrom_date === "" ? "null" : filterPurchaseFrom_date;
    //     var to_date = filterPurchaseTo_date === null || filterPurchaseTo_date === "" ? "null" : filterPurchaseTo_date;
    //     var URLfile = "null";
    //     var invoiceNrExt = filterPurchaseInvoiceNrExt === null || filterPurchaseInvoiceNrExt === "" ? "null" : filterPurchaseInvoiceNrExt;
    //     var invoiceNrInt = filterPurchaseInvoiceNrInt === null || filterPurchaseInvoiceNrInt === "" ? "null" : filterPurchaseInvoiceNrInt;
    //     var invoiceNrSch = filterPurchaseInvoiceNrSch === null || filterPurchaseInvoiceNrSch === "" ? "null" : filterPurchaseInvoiceNrSch;

    //     let q =
    //         hostsConfig.BACKEND_HOST +
    //         "/purchases_by_filters/" +
    //         supplier +
    //         "/" +
    //         from_date +
    //         "/" +
    //         to_date +
    //         "/" +
    //         URLfile +
    //         "/" +
    //         invoiceNrExt +
    //         "/" +
    //         invoiceNrInt +
    //         "/" +
    //         invoiceNrSch;
    //     async function getPurchasesByFilters() {
    //         try {
    //             const response = await axios.get(
    //                 hostsConfig.BACKEND_HOST +
    //                 "/purchases_by_filters/" +
    //                 supplier +
    //                 "/" +
    //                 from_date +
    //                 "/" +
    //                 to_date +
    //                 "/" +
    //                 URLfile +
    //                 "/" +
    //                 invoiceNrExt +
    //                 "/" +
    //                 invoiceNrInt +
    //                 "/" +
    //                 invoiceNrSch
    //             );
    //             console.log(response);
    //             let purchases = response.data.purchases;
    //             for (let i = 0; i < purchases.length; i++) {
    //                 purchases[i].id = purchases[i].purchaseID;
    //             }
    //             setPurchases(response.data.purchases);
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     }
    //     getPurchasesByFilters();

    //     setLoading(false);
    // };

    //toDelete
    // const handleClearFiltersPurchase = async () => {
    //     setFilterPurchaseSupplier("");
    //     setFilterPurchaseFrom_date("");
    //     setFilterPurchaseTo_date("");
    //     setFilterPurchaseInvoiceNrExt("");
    //     setFilterPurchaseInvoiceNrInt("");
    //     setFilterPurchaseInvoiceNrSch("");
    // };

    //toDelete
    // const handleSelectedSupplierPurchaseChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    //     setFilterPurchaseSupplier(event.target.value);
    // };

    const calculatePriceVATincluded = () => {
        if (priceVATexcl > 0 && VAT > 0) {
            setPriceVATincl((priceVATexcl * (1 + VAT / 100)).toFixed(2));
        } else {
            setPriceVATincl(0);
        }
    };

    const calculatePriceVATexcluded = () => {
        if (priceVATincl > 0 && VAT > 0) {
            setPriceVATexcl((priceVATincl / (1 + VAT / 100)).toFixed(2));
        } else {
            setPriceVATexcl(0);
        }
    };

    //toDelete
    // const handleRowClickPurchase: GridEventListener<"rowClick"> = async (params) => {
    //     setSelectedPurchase(params.id);
    //     if (selectedRows.length > 1 || productLastPurchaseObj === undefined || params.id !== productLastPurchaseObj.purchaseID) {
    //         setPriceVATexcl("");
    //         setVAT("");
    //         setPriceVATincl("");
    //         setAddInPurchaseDisabled(false);
    //         setChangePricesDisabled(true);
    //     } else {
    //         setPriceVATexcl(productLastPurchaseObj.priceVATexcl !== undefined ? productLastPurchaseObj.priceVATexcl : "");
    //         setVAT(productLastPurchaseObj.VAT !== undefined ? productLastPurchaseObj.VAT : "");
    //         setPriceVATincl(productLastPurchaseObj.priceVATincl !== undefined ? productLastPurchaseObj.priceVATincl : "");
    //         setAddInPurchaseDisabled(true);
    //         setChangePricesDisabled(false);
    //     }
    // };

    const handleSelectedNewSupplier = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setNewSupplierID(event.target.value);
    };

    const handleChangeNewPurchaseDate = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setNewPurchaseDate(event.target.value);
    };

    // const handleChangeNewInvoiceNrExt = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setNewInvoiceNrExt(event.target.value);
    // };

    const handleChangeNewInvoiceNrInt = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setNewInvoiceNrInt(event.target.value);
    };

    // const handleChangeNewInvoiceNrSch = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setNewInvoiceNrSch(event.target.value);
    // };

    const handleChangeNewPurchaseNotes = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setNewPurchaseNotes(event.target.value);
    };



const prepareInvoice = async () => {
    console.log("prepareInvoice");

    var fileObj = undefined;
    if (selectedFiles.length !== 0) {
        //find file object in array of object 'files' that has the same id with the selectedFiles[0]
        fileObj = files.filter((file) => file.id === selectedFiles[0])[0];
    }

    if(existingPurchase === null && fileObj === undefined &&!isFileConnected){
        setDialogFunction("saveInvoice");
        setOpenDialog(true);
        setDialogTitle("Save invoice");
        //Are you sure you want to create 'numberProducts' products?"
        setDialogMessage("Are you sure you want to create invoice without file ?");
        return;
    }else{
        handleAddNewPurshase();
    }
}



    const handleAddNewPurshase = async () => {
        console.log("handleAddPurchase");
        if (session.environment === undefined) {
            return;
        }

        let _productID = selectedRows[0];
 
        if (isFileConnected && newURLFiles.length === 0) {
            setMessage("Please select a file.");
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        if (newSupplierID === "" && inputSupplier === "") {
            setMessage("Please select a supplier.");
            setSeverity("error");
            setOpenMessage(true);
            return;
        }
        let _inputSupplier = inputSupplier;
        let _supplierID = newSupplierID;
        // let _purchaseID = selectedPurchase;
        
        if (newPurchaseDate === "") {
            setMessage("Please select a date.");
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        let _accountEmail = auth?.userInfo?.email || "";

        let _purchaseDate =
            newPurchaseDate !== null && newPurchaseDate !== ""
                ? newPurchaseDate
                : null;
        let _notes =
            newPurchaseNotes !== null && newPurchaseNotes !== ""
                ? newPurchaseNotes
                : null;
        let _invExt = null; //newInvoiceNrExt;
        let _invInt =
            newInvoiceNrInt !== null && newInvoiceNrInt !== ""
                ? newInvoiceNrInt
                : null; //newInvoiceNrInt;
        let _invSch = null; //newInvoiceNrSch;

        let _priceVATexcl =
            priceVATexcl !== null && priceVATexcl !== "" ? priceVATexcl : null;
        let _VAT = VAT !== null && VAT !== "" ? VAT : null;
        let _priceVATincl =
            priceVATincl !== null && priceVATincl !== "" ? priceVATincl : null;

        let _folder = driveDropdown;
        //let _filesArr = newURLFiles;

        var fileObj = undefined;
        if (selectedFiles.length !== 0) {
            //find file object in array of object 'files' that has the same id with the selectedFiles[0]
            fileObj = files.filter((file) => file.id === selectedFiles[0])[0];
        }

        
        if (existingPurchase === null) {
            //this product is not in any purchase

            if (fileObj === undefined) {
                //there is no file selected

                let newPurchase = await createInvoiceWithoutFile(
                    _productID,
                    _inputSupplier,
                    _supplierID,
                    _purchaseDate,
                    _invInt,
                    _notes,
                    _priceVATexcl,
                    _VAT,
                    _priceVATincl,
                    _accountEmail
                );

                if (
                    newPurchase !== null &&
                    newPurchase.purchase !== null &&
                    newPurchase.purchase.length > 0 &&
                    newPurchase.purchase[0] !== null
                ) {
                    //update array of products and set purchaseID to the new purchaseID. This is the purchaseID of the new purchase.
                    let newProducts = products.map((product) => {
                        if (product.id === _productID) {
                            product.currentPurchaseID =
                                newPurchase.purchase[0].purchaseID;
                        }
                        return product;
                    });
                    setProducts(newProducts);

                    let newExistingPurchase = {
                        purchaseID: newPurchase.purchase[0].purchaseID,
                        productID: _productID,
                        fileID: null,
                        supplierID: newPurchase.purchase[0].supplierID,
                        purchaseDate: newPurchase.purchase[0].purchaseDate,
                        purchaseNotes: newPurchase.purchase[0].notes,
                        invoiceNrInt: newPurchase.purchase[0].invoiceNrInt,
                    };
                    setExistingPurchase(newExistingPurchase);
                }

                setMessage(
                    "Create new invoice with the fields of the form with NO file."
                );
                setSeverity("success");
                setOpenMessage(true);
                setTimeout(() => {
                    setOpenMessage(false);
                }, 3000);

                return;
            } else {
                if (fileObj.purchaseID === null || fileObj.purchaseID === "") {
                    //this file is not connected to any purchase

                    let purchaseResult = await createInvoiceWithoutFile(
                        _productID,
                        _inputSupplier,
                        _supplierID,
                        _purchaseDate,
                        _invInt,
                        _notes,
                        _priceVATexcl,
                        _VAT,
                        _priceVATincl,
                        _accountEmail
                    );

                    if (
                        purchaseResult !== null &&
                        purchaseResult.purchase !== null &&
                        purchaseResult.purchase.length > 0 &&
                        purchaseResult.purchase[0] !== null
                    ) {
                        //update array of products and set purchaseID to the new purchaseID. This is the purchaseID of the new purchase.
                        let newProducts = products.map((product) => {
                            if (product.id === _productID) {
                                product.currentPurchaseID =
                                    purchaseResult.purchase[0].purchaseID;
                            }
                            return product;
                        });
                        setProducts(newProducts);

                        let newExistingPurchase = {
                            purchaseID: purchaseResult.purchase[0].purchaseID,
                            productID: _productID,
                            fileID: null,
                            supplierID: purchaseResult.purchase[0].supplierID,
                            purchaseDate:
                                purchaseResult.purchase[0].purchaseDate,
                            purchaseNotes: purchaseResult.purchase[0].notes,
                            invoiceNrInt:
                                purchaseResult.purchase[0].invoiceNrInt,
                        };
                        setExistingPurchase(newExistingPurchase);

                        let newPurchase = purchaseResult.purchase[0];

                        let translateType = null;
                        if (
                            MimeTypeDictionary[fileObj.mimeType] !== undefined
                        ) {
                            translateType =
                                MimeTypeDictionary[fileObj.mimeType];
                        }
                        let attachedfile = await attachFileToInvoice(
                            newPurchase.purchaseID,
                            fileObj.driveId,
                            fileObj.id,
                            fileObj.name,
                            translateType
                        );

                        if (attachedfile !== null) {
                            console.log("attachedfile");
                            console.log(attachedfile);
                            console.log("newPurchase");
                            console.log(newPurchase);
                            console.log("fileObj");
                            console.log(fileObj);

                            let newProducts = products.map((product) => {
                                if (product.id === _productID) {
                                    product.fileID = attachedfile.fileID;
                                    product.fileName = attachedfile.fileName;
                                }
                                return product;
                            });
                            setProducts(newProducts);

                            if (files.length > 0) {
                                let newFiles = files.map((singlefile) => {
                                    if (singlefile.id === fileObj.id) {
                                        singlefile.purchaseID =
                                            newPurchase.purchaseID;
                                        singlefile.purchaseDate =
                                            newPurchase.purchaseDate;
                                        singlefile.invoiceNrInt =
                                            newPurchase.invoiceNrInt;
                                        singlefile.supplierID =
                                            newPurchase.supplierID;
                                        let supplierName =
                                            session.environment.suppliers.find(
                                                (x) =>
                                                    x.SupplierID ===
                                                    newPurchase.supplierID
                                            ).SupplierName;
                                        singlefile.SupplierName = supplierName;
                                        singlefile.purchaseNotes =
                                            newPurchase.notes;
                                    }
                                    return singlefile;
                                });
                                setFiles(newFiles);
                            }
                        } else {
                            setMessage(
                                "Something went wrong : function 'attachFileToInvoice'"
                            );
                            setSeverity("error");
                            setOpenMessage(true);
                            return;
                        }
                    } else {
                        setMessage(
                            "Something went wrong : function 'createInvoiceWithoutFile'"
                        );
                        setSeverity("error");
                        setOpenMessage(true);
                        return;
                    }

                    setMessage(
                        "Create new invoice with the fields of the form and attach this file that is new ."
                    );
                    setSeverity("success");
                    setOpenMessage(true);
                    setTimeout(() => {
                        setOpenMessage(false);
                    }, 3000);
                } else {
                    //this file is connected to a purchase. PurchaseID : fileObj.purchaseID
                    // setMessage("Attach product to existing invoice and save all fields.");
                    // setSeverity("success");
                    // setOpenMessage(true);
                    // setTimeout(() => { setOpenMessage(false); }, 3000);
                }
            }
        } else {
            //this product is in a purchase. Details : existingPurchase

            if (
                existingPurchase.fileID === null ||
                existingPurchase.fileID === ""
            ) {
                //this product is in a purchase but the purchase has no file

                if (fileObj === undefined) {
                    //there is no file selected

                    let savedPurchase = await saveChangesWithoutFile(
                        existingPurchase.purchaseID,
                        _inputSupplier,
                        _supplierID,
                        _purchaseDate,
                        _invInt,
                        _notes,
                        _productID,
                        _priceVATexcl,
                        _VAT,
                        _priceVATincl,
                        _accountEmail
                    );

                    if (savedPurchase !== null) {
                        console.log("savedPurchase");
                        console.log(savedPurchase);
                    } else {
                        setMessage(
                            "Something went wrong : function 'saveChangesWithoutFile'"
                        );
                        setSeverity("error");
                        setOpenMessage(true);
                        return;
                    }

                    setMessage("Save all fields of invoice (without file).");
                    setSeverity("success");
                    setOpenMessage(true);
                    setTimeout(() => {
                        setOpenMessage(false);
                    }, 3000);
                } else {
                    if (
                        fileObj.purchaseID === null ||
                        fileObj.purchaseID === ""
                    ) {
                        //this file is not connected to any purchase

                        let savedPurchase = await saveChangesWithoutFile(
                            existingPurchase.purchaseID,
                            _inputSupplier,
                            _supplierID,
                            _purchaseDate,
                            _invInt,
                            _notes,
                            _productID,
                            _priceVATexcl,
                            _VAT,
                            _priceVATincl,
                            _accountEmail
                        );

                        if (savedPurchase !== null) {
                            console.log("savedPurchase");

                            let file = await attachFileToInvoice(
                                savedPurchase.purchaseID,
                                fileObj.driveId,
                                fileObj.id,
                                fileObj.name,
                                MimeTypeDictionary[fileObj.mimeType] !==
                                    undefined
                                    ? MimeTypeDictionary[fileObj.mimeType]
                                    : null
                            );

                            if (file !== null) {
                                console.log("file");
                                console.log(file);

                                let newProducts = products.map((product) => {
                                    if (product.id === _productID) {
                                        product.fileID = file.fileID;
                                        product.fileName = file.fileName;
                                    }
                                    return product;
                                });
                                setProducts(newProducts);

                                if (files.length > 0) {
                                    let newFiles = files.map((singlefile) => {
                                        if (singlefile.id === fileObj.id) {
                                            singlefile.purchaseID =
                                                savedPurchase.purchaseID;
                                            singlefile.purchaseDate =
                                                savedPurchase.purchaseDate;
                                            singlefile.invoiceNrInt =
                                                savedPurchase.invoiceNrInt;
                                            singlefile.supplierID =
                                                savedPurchase.supplierID;
                                            let supplierName =
                                                session.environment.suppliers.find(
                                                    (x) =>
                                                        x.SupplierID ===
                                                        savedPurchase.supplierID
                                                ).SupplierName;
                                            singlefile.SupplierName =
                                                supplierName;
                                            singlefile.purchaseNotes =
                                                savedPurchase.notes;
                                        }
                                        return singlefile;
                                    });
                                    setFiles(newFiles);
                                }
                            } else {
                                setMessage(
                                    "Something went wrong : function 'attachFileToInvoice'"
                                );
                                setSeverity("error");
                                setOpenMessage(true);
                                return;
                            }
                        } else {
                            setMessage(
                                "Something went wrong : function 'saveChangesWithoutFile'"
                            );
                            setSeverity("error");
                            setOpenMessage(true);
                            return;
                        }

                        //show message for three seconds
                        setMessage(
                            "Save all fields and attach file to this invoice."
                        );
                        setSeverity("success");
                        setOpenMessage(true);
                        setTimeout(() => {
                            setOpenMessage(false);
                        }, 3000);
                    } else {
                        //this file is connected to a purchase. PurchaseID : fileObj.purchaseID
                        //show warning message for three seconds
                        // setMessage("Do nothing. This file is already connected to a purchase.");
                        // setSeverity("error");
                        // setOpenMessage(true);
                        // setTimeout(() => { setOpenMessage(false); }, 3000);
                    }
                }
            } else {
                //this product is in a purchase and had a file

                if (fileObj === undefined) {
                    //there is no file selected

                    if (
                        existingPurchase.fileID !== null &&
                        existingPurchase.fileID !== "" &&
                        newURLFiles.length === 0
                    ) {
                        //purchase had a file and removed

                        let savedPurchase = await saveChangesWithoutFile(
                            existingPurchase.purchaseID,
                            _inputSupplier,
                            _supplierID,
                            _purchaseDate,
                            _invInt,
                            _notes,
                            _productID,
                            _priceVATexcl,
                            _VAT,
                            _priceVATincl,
                            _accountEmail
                        );

                        if (savedPurchase !== null) {
                            console.log("savedPurchase");
                            console.log(savedPurchase);

                            let success = await removeFileFromInvoice(
                                existingPurchase.purchaseID
                            );

                            if (success) {
                                console.log("File removed from invoice");

                                let newProducts = products.map((product) => {
                                    if (product.id === _productID) {
                                        product.fileID = null;
                                        product.fileName = null;
                                    }
                                    return product;
                                });
                                setProducts(newProducts);

                                if (files.length > 0) {
                                    let newFiles = files.map((singlefile) => {
                                        if (singlefile.id === existingPurchase.fileID) {
                                            singlefile.purchaseID = "";
                                            singlefile.purchaseDate = "";
                                            singlefile.invoiceNrInt = "";
                                            singlefile.supplierID = "";
                                            singlefile.SupplierName = "";
                                            singlefile.purchaseNotes = "";
                                        }
                                        return singlefile;
                                    });
                                    setFiles(newFiles);
                                }

                                let newExistingPurchase = {
                                    purchaseID: existingPurchase.purchaseID,
                                    productID: existingPurchase.productID,
                                    fileID: null,
                                    supplierID: existingPurchase.supplierID,
                                    purchaseDate: existingPurchase.purchaseDate,
                                    purchaseNotes: existingPurchase.purchaseNotes,
                                    invoiceNrInt: existingPurchase.invoiceNrInt,
                                };
                                setExistingPurchase(newExistingPurchase);

                            } else {
                                setMessage(
                                    "Something went wrong : function 'removeFileFromInvoice'"
                                );
                                setSeverity("error");
                                setOpenMessage(true);
                                return;
                            }
                        } else {
                            setMessage(
                                "Something went wrong : function 'saveChangesWithoutFile'"
                            );
                            setSeverity("error");
                            setOpenMessage(true);
                            return;
                        }

                        setMessage(
                            "Save all fields and remove file from invoice."
                        );
                        setSeverity("success");
                        setOpenMessage(true);
                        setTimeout(() => {
                            setOpenMessage(false);
                        }, 3000);
                    } else {
                        let savedPurchase = await saveChangesWithoutFile(
                            existingPurchase.purchaseID,
                            _inputSupplier,
                            _supplierID,
                            _purchaseDate,
                            _invInt,
                            _notes,
                            _productID,
                            _priceVATexcl,
                            _VAT,
                            _priceVATincl,
                            _accountEmail
                        );

                        if (savedPurchase !== null) {
                            console.log("savedPurchase");
                            console.log(savedPurchase);
                        } else {
                            setMessage(
                                "Something went wrong : function 'saveChangesWithoutFile'"
                            );
                            setSeverity("error");
                            setOpenMessage(true);
                            return;
                        }

                        setMessage("Save only fields (file is correct).");
                        setSeverity("success");
                        setOpenMessage(true);
                        setTimeout(() => {
                            setOpenMessage(false);
                        }, 3000);
                    }
                } else {
                    if (
                        fileObj.purchaseID === null ||
                        fileObj.purchaseID === ""
                    ) {
                        //this file is not connected to any purchase

                        let savedPurchase = await saveChangesWithoutFile(
                            existingPurchase.purchaseID,
                            _inputSupplier,
                            _supplierID,
                            _purchaseDate,
                            _invInt,
                            _notes,
                            _productID,
                            _priceVATexcl,
                            _VAT,
                            _priceVATincl,
                            _accountEmail
                        );

                        let newProducts = products.map((product) => {
                            if (product.id === _productID) {
                                product.currentPurchaseID =
                                    savedPurchase.purchase[0].purchaseID;
                            }
                            return product;
                        });
                        setProducts(newProducts);

                        if (savedPurchase !== null) {
                            console.log("savedPurchase");
                            console.log(savedPurchase);

                            let file = await attachFileToInvoice(
                                savedPurchase.purchaseID,
                                fileObj.driveId,
                                fileObj.id,
                                fileObj.name,
                                MimeTypeDictionary[fileObj.mimeType] !==
                                    undefined
                                    ? MimeTypeDictionary[fileObj.mimeType]
                                    : null
                            );

                            if (file !== null) {
                                console.log("file");
                                console.log(file);

                                let newProducts = products.map((product) => {
                                    if (product.id === _productID) {
                                        product.fileID = file.fileID;
                                        product.fileName = file.fileName;
                                    }
                                    return product;
                                });
                                setProducts(newProducts);

                                if (files.length > 0) {
                                    let newFiles = files.map((singlefile) => {
                                        if (singlefile.id === fileObj.id) {
                                            singlefile.purchaseID =
                                                savedPurchase.purchaseID;
                                            singlefile.purchaseDate =
                                                savedPurchase.purchaseDate;
                                            singlefile.invoiceNrInt =
                                                savedPurchase.invoiceNrInt;
                                            singlefile.supplierID =
                                                savedPurchase.supplierID;
                                            let supplierName =
                                                session.environment.suppliers.find(
                                                    (x) =>
                                                        x.SupplierID ===
                                                        savedPurchase.supplierID
                                                ).SupplierName;
                                            singlefile.SupplierName =
                                                supplierName;
                                            singlefile.purchaseNotes =
                                                savedPurchase.notes;
                                        }
                                        return singlefile;
                                    });
                                    setFiles(newFiles);
                                }
                            } else {
                                setMessage(
                                    "Something went wrong : function 'attachFileToInvoice'"
                                );
                                setSeverity("error");
                                setOpenMessage(true);
                                return;
                            }
                        } else {
                            setMessage(
                                "Something went wrong : function 'saveChangesWithoutFile'"
                            );
                            setSeverity("error");
                            setOpenMessage(true);
                            return;
                        }

                        setMessage(
                            "Save fields and attach new file to it (remove old file from invoice !!!)."
                        );
                        setSeverity("success");
                        setOpenMessage(true);
                        setTimeout(() => {
                            setOpenMessage(false);
                        }, 3000);
                    } else {
                        if (
                            existingPurchase.purchaseID === fileObj.purchaseID
                        ) {
                            //this file is connected to the same purchase as the product

                            let savedPurchase = await saveChangesWithoutFile(
                                existingPurchase.purchaseID,
                                _inputSupplier,
                                _supplierID,
                                _purchaseDate,
                                _invInt,
                                _notes,
                                _productID,
                                _priceVATexcl,
                                _VAT,
                                _priceVATincl,
                                _accountEmail
                            );

                            if (savedPurchase !== null) {
                                console.log("savedPurchase");
                                console.log(savedPurchase);
                            } else {
                                setMessage(
                                    "Something went wrong : function 'saveChangesWithoutFile'"
                                );
                                setSeverity("error");
                                setOpenMessage(true);
                                return;
                            }

                            //show warning message for three seconds
                            setMessage(
                                "Save only fields (file is already correct)."
                            );
                            setSeverity("success");
                            setOpenMessage(true);
                            setTimeout(() => {
                                setOpenMessage(false);
                            }, 3000);
                        } else {
                            //this file is connected to a different purchase than the product

                            //show warning message for three seconds
                            setMessage(
                                "Do nothing. This file is already connected to a different purchase."
                            );
                            setSeverity("error");
                            setOpenMessage(true);
                            setTimeout(() => {
                                setOpenMessage(false);
                            }, 3000);
                            return;
                        }
                    }
                }
            }
        }

        return;

        async function createInvoiceWithoutFile(
            _productID,
            _inputSupplier,
            _supplierID,
            _purchaseDate,
            _invInt,
            _notes,
            _priceVATexcl,
            _VAT,
            _priceVATincl,
            _accountEmail
        ) {
            try {
                let body = {
                    productID: _productID,
                    inputSupplier: _inputSupplier,
                    supplierID: _supplierID,
                    purchaseDate: _purchaseDate,
                    invoiceNrInt: _invInt !== null ? _invInt : "",
                    notes: _notes !== null ? _notes : "",
                    priceVATexcl: _priceVATexcl !== null ? _priceVATexcl : "",
                    VAT: _VAT !== null ? _VAT : "",
                    priceVATincl: _priceVATincl !== null ? _priceVATincl : "",
                    accountEmail: _accountEmail,
                };

                const response = await axios.post(
                    hostsConfig.BACKEND_HOST + "/add_new_purchase_without_file_with_inputSupplier",
                    body
                );

                if(_supplierID === "" && _inputSupplier !== ""){
                    let newSupplier = {
                        SupplierID: response.data.supplier[0].SupplierID,
                        SupplierName: _inputSupplier
                    }
                    let newSuppliers = session.environment.suppliers;
                    newSuppliers.push(newSupplier);

                    let newSession = session;
                    newSession.environment.suppliers = newSuppliers;

                    setSession(newSession);

                    setNewSupplierID(response.data.supplier[0].SupplierID);
                    setSuppliers(newSuppliers);
                }

                console.log(response);

                return response.data; //.purchase[0];
            } catch (error) {
                console.error(error);
                return null;
            }
        }

        async function attachFileToInvoice(
            _purchaseID,
            _driveID,
            _fileID,
            _name,
            _type
        ) {
            try {
                let body = {
                    purchaseID: _purchaseID,
                    driveID: _driveID,
                    fileID: _fileID,
                    fileName: _name,
                    fileType: _type,
                };

                const response = await axios.post(
                    hostsConfig.BACKEND_HOST + "/attach_file_to_invoice",
                    body
                );

                console.log(response);

                return response.data.file[0];
            } catch (error) {
                console.error(error);
                return null;
            }
        }

        async function saveChangesWithoutFile(
            _purchaseID,
            _inputSupplier,
            _supplierID,
            _purchaseDate,
            _invInt,
            _notes,
            _productID,
            _priceVATexcl,
            _VAT,
            _priceVATincl,
            _accountEmail
        ) {
            try {
                let body = {
                    purchaseID: _purchaseID,
                    inputSupplier: _inputSupplier,
                    supplierID: _supplierID,
                    purchaseDate: _purchaseDate,
                    invoiceNrInt: _invInt !== null ? _invInt : "",
                    notes: _notes !== null ? _notes : "",
                    productID: _productID,
                    priceVATexcl: _priceVATexcl !== null ? _priceVATexcl : "",
                    VAT: _VAT !== null ? _VAT : "",
                    priceVATincl: _priceVATincl !== null ? _priceVATincl : "",
                    accountEmail: _accountEmail,
                };

                const response = await axios.post(
                    hostsConfig.BACKEND_HOST + "/save_changes_without_file_with_inputSupplier",
                    body
                );

                console.log(response);

                if(_supplierID === "" && _inputSupplier !== ""){
                    let newSupplier = {
                        SupplierID: response.data.supplier[0].SupplierID,
                        SupplierName: _inputSupplier
                    }
                    let newSuppliers = session.environment.suppliers;
                    newSuppliers.push(newSupplier);

                    let newSession = session;
                    newSession.environment.suppliers = newSuppliers;

                    setSession(newSession);
                    setNewSupplierID(response.data.supplier[0].SupplierID);
                    setSuppliers(newSuppliers);
                }

                return response.data.purchase[0];
            } catch (error) {
                console.error(error);
                return null;
            }
        }

        async function removeFileFromInvoice(_purchaseID) {
            try {
                let body = {
                    purchaseID: _purchaseID,
                };

                const response = await axios.post(
                    hostsConfig.BACKEND_HOST + "/remove_file_from_invoice",
                    body
                );

                let success = response.data.file.length === 0;
                console.log(success);

                return success;
            } catch (error) {
                console.error(error);
                return null;
            }
        }
    };

    const handleConnectToThisPurshase = async () => {
        console.log("handleConnectToThisPurchase");
        console.log(invoicesSelectedPurchaseID);

        var fileObj = undefined;
        if (selectedFiles.length !== 0) {
            //find file object in array of object 'files' that has the same id with the selectedFiles[0]
            fileObj = files.filter((file) => file.id === selectedFiles[0])[0];
        }

        if (session.environment === undefined) {
            return;
        }

        let _productID = selectedRows[0];

        if (_productID === undefined) {
            setMessage("Please select a product first.");
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        if (newSupplierID === "" && inputSupplier === "") {
            setMessage("Please select a supplier.");
            setSeverity("error");
            setOpenMessage(true);
            return;
        }
        let _inputSupplier = inputSupplier;
        let _supplierID = newSupplierID;
        // let _purchaseID = selectedPurchase;

        if (newPurchaseDate === "") {
            setMessage("Please select a date.");
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        if (newURLFiles.length === 0) {
            setMessage("Please select a file.");
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        let _purchaseDate = newPurchaseDate;
        let _notes = newPurchaseNotes;
        let _invExt = null; //ewInvoiceNrExt;
        let _invInt = newInvoiceNrInt;
        let _invSch = null; //newInvoiceNrSch;

        let _folder = driveDropdown;
        let _filesArr = newURLFiles;

        let _priceVATexcl =
            priceVATexcl !== null && priceVATexcl !== "" ? priceVATexcl : null;
        let _VAT = VAT !== null && VAT !== "" ? VAT : null;
        let _priceVATincl =
            priceVATincl !== null && priceVATincl !== "" ? priceVATincl : null;

        let _accountEmail = auth?.userInfo?.email || "";

        try {
            let body = {
                productID: _productID,
                purchaseID: fileObj.purchaseID,
                inputSupplier: _inputSupplier,
                supplierID: _supplierID,
                purchaseDate: _purchaseDate,
                invoiceNrInt: _invInt !== null ? _invInt : "",
                notes: _notes !== null ? _notes : "",
                priceVATexcl: _priceVATexcl !== null ? _priceVATexcl : "",
                VAT: _VAT !== null ? _VAT : "",
                priceVATincl: _priceVATincl !== null ? _priceVATincl : "",
                accountEmail: _accountEmail,
            };

            const response = await axios.post(
                hostsConfig.BACKEND_HOST +
                "/add_product_to_purchase_save_purchase_details",
                body
            );
            //refreshProducts();
            console.log(response);

            if (response.data.purchase.length !== 0) {
                setExistingPurchase(response.data.purchase[0]);
            }

            setMessage("Product added in this invoice.");
            setSeverity("success");
            setOpenMessage(true);
        } catch (error) {
            console.error(error);
            setMessage("Something went wrong, please try again");
            setSeverity("error");
            setOpenMessage(true);
        }
    };

    const handleGetFiles = async () => {
        try {
            // if (selectedPurchase === null || selectedPurchase === "") {
            //     setMessage("Please select a purchase.");
            //     setSeverity("error");
            //     setOpenMessage(true);
            //     return;
            // }

            let FOLDER_ID = "";

            if (driveDropdown === null || driveDropdown === "") {
                setMessage("Please select a drive.");
                setSeverity("error");
                setOpenMessage(true);
                return;
            } else {
                FOLDER_ID = session.environment.drives.filter(
                    (drive) => drive.driveGUID === driveDropdown
                )[0].driveID;
            }

            setLoading(true);
            const token = await getToken();
            //const FOLDER_ID = "1J4AgSIHUAxxfXhWskvXbH9QELDYns2uZ"; // SGR - programming / inventory application
            const url = `https://www.googleapis.com/drive/v3/files?q='${FOLDER_ID}'+in+parents+and+trashed=false`;

            var allFiles = [];
            var allSpreadsheet = [];
            var allFolders = [];

            const requestDrive = await fetch(
                `https://www.googleapis.com/drive/v3/files?corpora=drive&driveId=${FOLDER_ID}&includeTeamDriveItems=true&supportsAllDrives=true`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            );
            const dataDrive = await requestDrive.json();
            allFiles = dataDrive.files;
            allSpreadsheet = allFiles.filter(
                (file) =>
                    file.mimeType === "application/vnd.google-apps.spreadsheet"
            );
            allFolders = allFiles.filter(
                (file) => file.mimeType === "application/vnd.google-apps.folder"
            );

            let nextToken = dataDrive.nextPageToken;

            while (nextToken !== undefined) {
                const requestDrive = await fetch(
                    `https://www.googleapis.com/drive/v3/files?corpora=drive&driveId=${FOLDER_ID}&includeTeamDriveItems=true&supportsAllDrives=true&pageToken=${nextToken}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                    }
                );
                const dataDrive = await requestDrive.json();
                allFiles = [...allFiles, ...dataDrive.files];
                allSpreadsheet = [
                    ...allSpreadsheet,
                    ...dataDrive.files.filter(
                        (file) =>
                            file.mimeType ===
                            "application/vnd.google-apps.spreadsheet"
                    ),
                ];
                allFolders = [
                    ...allFolders,
                    ...dataDrive.files.filter(
                        (file) =>
                            file.mimeType ===
                            "application/vnd.google-apps.folder"
                    ),
                ];

                nextToken = dataDrive.nextPageToken;
            }

            setFiles(allSpreadsheet);
        } catch (error) {
            console.log("userInfo 35 | error getting calendar data", error);
            setMessage(error.message);
            setSeverity("error");
            setOpenMessage(true);
            setLoading(false);
            return error.message;
        }
        setLoading(false);
    };

    const handleGetFilesSharedDrive = async () => {
        try {
            // if (selectedPurchase === null || selectedPurchase === "") {
            //     setMessage("Please select a purchase.");
            //     setSeverity("error");
            //     setOpenMessage(true);
            //     return;
            // }

            let FOLDER_ID = "";

            if (driveDropdown === null || driveDropdown === "") {
                setMessage("Please select a drive.");
                setSeverity("error");
                setOpenMessage(true);
                return;
            } else {
                FOLDER_ID = session.environment.drives.filter(
                    (drive) => drive.driveGUID === driveDropdown
                )[0].driveID;
            }

            setLoading(true);
            const token = await getToken();
            //const FOLDER_ID = "1J4AgSIHUAxxfXhWskvXbH9QELDYns2uZ"; // SGR - programming / inventory application
            const url = `https://www.googleapis.com/drive/v3/files?corpora=drive&driveId=${FOLDER_ID}&includeItemsFromAllDrives=true&supportsAllDrives=true`;

            var all_PDF_Word_Files = [];
            var files_filtered = [];

            // var allFolders = [];
            var filter = filterFilesByName;

            const requestDrive = await fetch(
                `https://www.googleapis.com/drive/v3/files?q='${FOLDER_ID}' in parents&includeItemsFromAllDrives=true&supportsAllDrives=true`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            );
            const dataDrive = await requestDrive.json();
            all_PDF_Word_Files = dataDrive.files;

            all_PDF_Word_Files = all_PDF_Word_Files.filter(
                (file) =>
                    file.mimeType === "application/pdf" ||
                    file.mimeType === "application/vnd.google-apps.document"
            );

            // filter files that has the filter string in the name even part of the name
            if (filter !== "") {
                files_filtered = all_PDF_Word_Files.filter((file) =>
                    file.name.toLowerCase().includes(filter.toLowerCase())
                );
            } else {
                files_filtered = all_PDF_Word_Files;
            }

            // allFolders = allFiles.filter((file) => file.mimeType === "application/vnd.google-apps.folder");

            let nextToken = dataDrive.nextPageToken;

            while (nextToken !== undefined) {
                const requestDrive = await fetch(
                    `https://www.googleapis.com/drive/v3/files?corpora=allDrives&driveId=${FOLDER_ID}&includeTeamDriveItems=true&supportsAllDrives=true&pageToken=${nextToken}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                    }
                );
                const dataDrive = await requestDrive.json();
                all_PDF_Word_Files = dataDrive.files;
                all_PDF_Word_Files = all_PDF_Word_Files.filter(
                    (file) =>
                        file.mimeType === "application/pdf" ||
                        file.mimeType === "application/vnd.google-apps.document"
                );

                if (filter !== "") {
                    files_filtered = [
                        ...files_filtered,
                        ...dataDrive.files.filter((file) =>
                            file.name
                                .toLowerCase()
                                .includes(filter.toLowerCase())
                        ),
                    ];
                } else {
                    files_filtered = [...files_filtered, ...dataDrive.files];
                }

                // filter files that has the filter string in the name even part of the name

                // allFolders = [...allFolders, ...dataDrive.files.filter((file) => file.mimeType === "application/vnd.google-apps.folder")];

                nextToken = dataDrive.nextPageToken;
            }

            var invoiceFilesDB = [];
            const getFilesFromDB = async (files_filtered, _FOLDER_ID) => {
                try {
                    const response = await axios.get(
                        hostsConfig.BACKEND_HOST +
                        "/getInvoiceFiles/" +
                        driveDropdown
                    );
                    console.log(response);
                    invoiceFilesDB = response.data.invoiceFiles;
                    setInvoiceFilesDB(response.data.invoiceFiles);

                    for (let i = 0; i < files_filtered.length; i++) {
                        let file = files_filtered[i];
                        let invoiceFileDB = invoiceFilesDB.filter(
                            (invoiceFile) => invoiceFile.fileID === file.id
                        );
                        if (invoiceFileDB.length > 0) {
                            files_filtered[i].purchaseID =
                                invoiceFileDB[0].purchaseID;
                            files_filtered[i].purchaseDate =
                                invoiceFileDB[0].purchaseDate;
                            files_filtered[i].invoiceNrInt =
                                invoiceFileDB[0].invoiceNrInt;
                            files_filtered[i].SupplierName =
                                invoiceFileDB[0].SupplierName;
                            files_filtered[i].supplierID =
                                invoiceFileDB[0].supplierID;
                            files_filtered[i].purchaseNotes =
                                invoiceFileDB[0].purchaseNotes;
                            files_filtered[i].productCount =
                                invoiceFileDB[0].productCount;
                        } else {
                            files_filtered[i].purchaseID = "";
                            files_filtered[i].purchaseDate = "";
                            files_filtered[i].invoiceNrInt = "";
                            files_filtered[i].SupplierName = "";
                            files_filtered[i].supplierID = "";
                            files_filtered[i].purchaseNotes = "";
                            files_filtered[i].productCount = "";
                        }
                        files_filtered[i].driveId = _FOLDER_ID;
                    }

                    setFiles(files_filtered);
                } catch (error) {
                    console.error(error);
                }
            };
            getFilesFromDB(files_filtered, FOLDER_ID);

            //for every file in files_filtered if there is a file in invoiceFilesDB with the same id, add the invoiceFileDB note attribute to the file

            // setFiles(files_filtered);
        } catch (error) {
            console.log("userInfo 35 | error getting calendar data", error);
            setMessage(error.message);
            setSeverity("error");
            setOpenMessage(true);
            setLoading(false);
            return error.message;
        }
        setLoading(false);
    };

    // handleDelete is a function that deletes get the key of the selected chip and deletes it from the array of selected chips
    const handleDeleteFile = (fileToDelete) => () => {
        let filesArr = newURLFiles.filter((chip) => chip.id !== fileToDelete);
        setNewURLFiles(filesArr);
        let file_ids = filesArr.map((file) => file.id);
        setSelectedFiles(file_ids);

        if (existingPurchase !== null) {
            setMessage(
                "File removed but not saved yet. Please click Save button to save the changes."
            );
            setSeverity("warning");
            setOpenMessage(true);
        }

    };

    const handleClickFile = (fileToDelete) => () => {
        console.log(fileToDelete);
    };

    const handleRowClickFile: GridEventListener<"rowClick"> = async (
        params
    ) => {
        try {
            //if params.id is not included in the selectedFiles array, add it.
            if (selectedFiles.includes(params.id)) {
                setSelectedFiles(null);
            } else {
                setSelectedFiles(params.id);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleRowDoubleClickFile = async (params) => {
        try {
            //if params.id is not included in the selectedFiles array, add it.
            if (selectedFiles.includes(params.id)) {
                setSelectedFiles(null);
            } else {
                setSelectedFiles(params.id);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSelectionFileModelChange = (ids) => {
        try {

            if(selectedFiles.length > 0 && selectedFiles[0] === ids[0] && existingPurchase === null){
                setSelectedFiles([]);

                setNewURLFiles([]);
                
                setIsFileConnected(true);

                setDisableAddPurchase(false);
                setDisableChangePurchase(true);

                setDisableIsFileConnected(false);
                setDisableCanRemoveFile(false);
                setDisableSupplier(false);
                setDisablePurchaseDate(false);
                setDisableInvoiceNrInt(false);
                setDisablePurchaseNotes(false);

                setOptionSupplier("");
                setNewSupplierID("");
                setNewPurchaseDate("");
                setNewInvoiceNrInt("");
                setNewPurchaseNotes("");

                return;
            }

            setSelectedFiles(ids);

            let filesArr = files.filter((file) => ids.includes(file.id));

            //find file object that has the same id with the ids[0]
            let fileObj = filesArr[0];

            let myFileArray = filesArr.map((file) => {
                return {
                    id: file.id,
                    name: file.name,
                };
            });

            console.log(existingPurchase);
            //console.log(selectedPurchase);
            console.log(invoicesSelectedPurchaseID);

            console.log(filesArr[0]);

            if (existingPurchase === null) {
                //this product is not in any purchase

                if (fileObj.purchaseID === null || fileObj.purchaseID === "") {
                    //this file is not connected to any purchase
                    setNewURLFiles(myFileArray);
                    setIsFileConnected(true);

                    setDisableAddPurchase(false);
                    setDisableChangePurchase(true);

                    setDisableIsFileConnected(false);
                    setDisableCanRemoveFile(false);
                    setDisableSupplier(false);
                    setDisablePurchaseDate(false);
                    setDisableInvoiceNrInt(false);
                    setDisablePurchaseNotes(false);

                    setOptionSupplier("");
                    setNewSupplierID("");
                    setNewPurchaseDate("");
                    setNewInvoiceNrInt("");
                    setNewPurchaseNotes("");

                    //show message for three seconds
                    setMessage("File changed but not saved yet.");
                    setSeverity("success");
                    setOpenMessage(true);
                    setTimeout(() => {
                        setOpenMessage(false);
                    }, 3000);
                } else {
                    //this file is connected to a purchase. PurchaseID : fileObj.purchaseID
                    setNewURLFiles(myFileArray);
                    setIsFileConnected(true);

                    setNewSupplierID(fileObj.supplierID);

                    if(fileObj.supplierID !== ""){
                        let supplier = session.environment.suppliers.filter(
                            (supplier) => supplier.SupplierID === fileObj.supplierID
                        )[0];
                        setOptionSupplier(supplier.SupplierName);
                    }else{
                        setOptionSupplier("");
                    }

                    setNewPurchaseDate(
                        moment(fileObj.purchaseDate).format("YYYY-MM-DD")
                    );
                    setNewInvoiceNrInt(fileObj.invoiceNrInt);
                    setNewPurchaseNotes(fileObj.purchaseNotes);

                    setDisableAddPurchase(true);
                    setDisableChangePurchase(false);

                    setDisableIsFileConnected(true);
                    setDisableCanRemoveFile(true);
                    setDisableSupplier(true);
                    setDisablePurchaseDate(true);
                    setDisableInvoiceNrInt(true);
                    setDisablePurchaseNotes(true);

                    //show message for three seconds
                    setMessage("Purchase changed but not saved yet.");
                    setSeverity("success");
                    setOpenMessage(true);
                    setTimeout(() => {
                        setOpenMessage(false);
                    }, 3000);
                }
            } else {

                setMessage(
                    "This product is already connected to a purchase."
                );
                setSeverity("error");
                setOpenMessage(true);
                setTimeout(() => {
                    setOpenMessage(false);
                }, 3000);
                return;
                //this product is in a purchase. Details : existingPurchase

                // setNewSupplierID(existingPurchase.supplierID);
                // setNewPurchaseDate(
                //     moment(existingPurchase.purchaseDate).format("YYYY-MM-DD")
                // );
                // setNewInvoiceNrInt(existingPurchase.invoiceNrInt);
                // setNewPurchaseNotes(existingPurchase.purchaseNotes);

                // if (
                //     existingPurchase.fileID === null ||
                //     existingPurchase.fileID === ""
                // ) {
                //     //this product is in a purchase but the purchase has no file

                //     if (
                //         fileObj.purchaseID === null ||
                //         fileObj.purchaseID === ""
                //     ) {
                //         //this file is not connected to any purchase
                //         setNewURLFiles(myFileArray);

                //         setDisableAddPurchase(false);
                //         setDisableChangePurchase(true);

                //         //show message for three seconds
                //         setMessage("File changed but not saved yet.");
                //         setSeverity("success");
                //         setOpenMessage(true);
                //         setTimeout(() => {
                //             setOpenMessage(false);
                //         }, 3000);
                //     } else {
                //         //this file is connected to a purchase. PurchaseID : fileObj.purchaseID

                //         setDisableAddPurchase(true);
                //         setDisableChangePurchase(true);

                //         //show warning message for three seconds
                //         setMessage(
                //             "This file is already connected to a purchase."
                //         );
                //         setSeverity("error");
                //         setOpenMessage(true);
                //         setTimeout(() => {
                //             setOpenMessage(false);
                //         }, 3000);
                //     }
                // } else {
                //     //this product is in a purchase and the purchase has a file

                //     if (
                //         fileObj.purchaseID === null ||
                //         fileObj.purchaseID === ""
                //     ) {
                //         //this file is not connected to any purchase

                //         // setNewURLFiles(myFileArray);
                //         // setSelectedFiles(ids);
                //         // setDisableAddPurchase(false);
                //         // setDisableChangePurchase(true);

                //         setMessage(
                //             "This invoice has already a file."
                //         );
                //         setSeverity("error");
                //         setOpenMessage(true);
                //         setTimeout(() => {
                //             setOpenMessage(false);
                //         }, 3000);
                //     } else {
                //         if (
                //             existingPurchase.purchaseID === fileObj.purchaseID
                //         ) {
                //             //this file is connected to the same purchase as the product
                //             setSelectedFiles(ids);

                //             // setDisableAddPurchase(true);
                //             setDisableChangePurchase(true);

                //             //show warning message for three seconds
                //             setMessage(
                //                 "Bingo!!! This file is already connected to this purchase."
                //             );
                //             setSeverity("error");
                //             setOpenMessage(true);
                //             setTimeout(() => {
                //                 setOpenMessage(false);
                //             }, 3000);
                //         } else {
                //             //this file is connected to a different purchase than the product
                //             //setSelectedFiles([]);

                //             setDisableAddPurchase(true);
                //             setDisableChangePurchase(true);

                //             //show warning message for three seconds
                //             setMessage(
                //                 "This file is already connected to a different purchase."
                //             );
                //             setSeverity("error");
                //             setOpenMessage(true);
                //             setTimeout(() => {
                //                 setOpenMessage(false);
                //             }, 3000);
                //         }
                //     }
                // }
            }

            // async function purchasesData(_purchaseID) {
            //     try {
            //         const response = await axios.get(hostsConfig.BACKEND_HOST + "/purchases_by_id/" + _purchaseID);
            //         console.log(response.data.purchases);
            //         console.log(setSelectedProduct);

            //         let purchases = response.data.purchases;

            //         //for every purchase, get the purshaseID and add it to the array as "id" also
            //         for (let i = 0; i < purchases.length; i++) {
            //             purchases[i].id = purchases[i].purchaseID;
            //         }

            //         setPurchases(response.data.purchases);

            //         //find the purchase with the _purchaseID and set foundInLine to rowNumber
            //         let foundInLine = -1;
            //         for (let i = 0; i < purchases.length; i++) {
            //             if (purchases[i].productID === selectedProduct) {
            //                 foundInLine = i;
            //                 break;
            //             }
            //         }

            //         if (foundInLine === -1) {
            //             setPriceVATexcl("");
            //             setVAT("");
            //             setPriceVATincl("");

            //             setDisableChangePurchase(false);
            //         } else {
            //             setPriceVATexcl(purchases[foundInLine].priceVATexcl !== null ? purchases[foundInLine].priceVATexcl : "");
            //             setVAT(purchases[foundInLine].VAT !== null ? purchases[foundInLine].VAT : "");
            //             setPriceVATincl(purchases[foundInLine].priceVATincl !== null ? purchases[foundInLine].priceVATincl : "");

            //             setDisableChangePurchase(true);
            //         }

            //         setNewSupplierID(purchases[0].supplierID);

            //         var date = new Date(purchases[0].purchaseDate);
            //         const year = date.getFullYear();
            //         const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero if needed
            //         const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if needed

            //         setNewPurchaseDate(`${year}-${month}-${day}`);

            //         setInvoicesSelectedPurchaseID(purchases[0].purchaseID);
            //         setNewInvoiceNrInt(purchases[0].invoiceNrInt);
            //         setNewPurchaseNotes(purchases[0].purchaseNotes);

            //         let file = { id: purchases[0].fileID, name: purchases[0].fileName };

            //         setNewURLFiles([file]);

            //         setDriveDropdown(purchases[0].driveGUID);
            //     } catch (error) {
            //         console.error(error);
            //     }
            // }

            // if (allSamePurchaseID && purchaseID !== "") {
            //     purchasesData(purchaseID);

            //     setDisableAddPurchase(true);
            //     if (existingPurchase !== null && purchaseID === existingPurchase.purchaseID) {
            //         setDisableChangePurchase(true);
            //     } else {
            //         setDisableChangePurchase(false);
            //     }
            // } else {
            //     setPurchases([]);

            //     setNewSupplierID("");
            //     setNewPurchaseDate("");
            //     setInvoicesSelectedPurchaseID("");
            //     setNewInvoiceNrInt("");
            //     setNewPurchaseNotes("");
            //     setPriceVATexcl("");
            //     setVAT("");
            //     setPriceVATincl("");

            //     setDisableAddPurchase(false);
            //     setDisableChangePurchase(true);
            // }
        } catch (e) {
            console.log(e);
        }
    };

    const handleSelectedDriveChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setDriveDropdown(event.target.value);
    };

    const handleCloseBackdrop = () => {
        setLoading(false);
    };
    const handleOpenBackdrop = () => {
        setLoading(true);
    };

    const handleChangeFilterFilesByName = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setFilterFilesByName(event.target.value);
    };

    const handleChangeisFileConnected = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setIsFileConnected(event.target.checked);
        if (!event.target.checked) {
            setNewURLFiles([]);
            setSelectedFiles([]);
            
        }
    };






    const handleOptionSupplierChange = (event: any, newValue: string | null) => {

        setOptionSupplier(newValue);
        console.log(newValue);
        if (newValue !== inputSupplier) {
            setSupplierNotFound(true);
        } else {
            setSupplierNotFound(false);
        }

        //if newValue belongs to the locations of the selected school then set selectedLocation to id of that location
        // let school = schools.filter((s) => s.id === selectedSchool)[0];
        // if (school.locations.filter((l) => l.title === newValue).length > 0) {
        //     setSelectedLocation(school.locations.filter((l) => l.description === newValue)[0].id);
        // } else {
        //     setSelectedLocation("");
        // }

        if(session.environment?.suppliers){
            let supplier = session.environment.suppliers.filter((s) => s.SupplierName === newValue)[0];
            if(supplier){
                setNewSupplierID(supplier.SupplierID);
            }else{
                setNewSupplierID("");
            }
        }


    };

    const handleInputSupplierChange = (event, newInputValue) => {

        setInputSupplier(newInputValue);
        console.log(newInputValue);
        if (newInputValue !== optionSupplier) {
            setSupplierNotFound(true);
        } else {
            setSupplierNotFound(false);
        }

        if(session.environment?.suppliers){
            let supplier = session.environment.suppliers.filter((s) => s.SupplierName === newInputValue)[0];
            if(supplier){
                setNewSupplierID(supplier.SupplierID);
            }else{
                setNewSupplierID("");
            }
        }
    };

    return (
        <Box className="mainBox" sx={{ m: 2, width: "100%" }}>
            <Grid container spacing={0}>
                {/* <Grid item md={12} lg={12}>
                    <Typography align="center" variant="h5">
                        Edit products
                    </Typography>
                </Grid> */}

                {steps && (
                    <Grid item md={12} lg={12}>
                        <Box
                            sx={{
                                ml: 0,
                                width: "95%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            {Array.isArray(steps) &&
                                steps.map((step) => (
                                    <Box
                                        key={`div${step.id}`}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        {step.id === activeStep ? (
                                            <>
                                                <Box
                                                    sx={{ maxWidth: 200 }}
                                                    p={1}
                                                    key={step.id}
                                                >
                                                    <Button
                                                        variant="text"
                                                        onClick={() => { }}
                                                        sx={{ color: "black" }}
                                                    >
                                                        {step.label}
                                                    </Button>
                                                </Box>
                                            </>
                                        ) : (
                                            <Box
                                                sx={{ maxWidth: 200 }}
                                                p={1}
                                                key={step.id}
                                            >
                                                <Button
                                                    variant="text"
                                                    onClick={() => {
                                                        setActiveStep(step.id);
                                                    }}
                                                >
                                                    {step.label}
                                                </Button>
                                            </Box>
                                        )}
                                        {step.hasNext && (
                                            <Box
                                                key={`divider${step.id}`}
                                                sx={{
                                                    width: 20,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Typography
                                                    key={`divider${step.id}`}
                                                >
                                                    &gt;
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                ))}

                            <Box sx={{ flex: "1 1 auto" }} />
                            {steps && (
                                <>
                                    <Box pb={1}>
                                        <Button
                                            variant="contained"
                                            sx={{ minWidth: 150 }}
                                            disabled={
                                                steps[activeStep - 1]
                                                    ?.previouseButtonDisabled
                                            }
                                            onClick={() => {
                                                setActiveStep(activeStep - 1);
                                            }}
                                        >
                                            {
                                                steps[activeStep - 1]
                                                    ?.previousButtonLabel
                                            }
                                        </Button>

                                        <Button
                                            variant="contained"
                                            sx={{ minWidth: 150 }}
                                            disabled={
                                                steps[activeStep - 1]
                                                    ?.nextButtonDisabled
                                            }
                                            onClick={handleNext}
                                        >
                                            {
                                                steps[activeStep - 1]
                                                    ?.nextButtonLabel
                                            }
                                        </Button>
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Grid>
                )}

                <Grid item md={12} lg={12}>
                    <Card variant="plain" sx={{ mr: 4, p: 1 }}>
                        {/* <Grid item md={12} lg={12}> */}
                        {/* <Typography>Schools</Typography> */}

                        {/*==== Filters ==== */}

                        {activeStep === 1 && (
                            <>
                                <Accordion
                                    expanded={filtersExplanded}
                                    onChange={() => {
                                        setFiltersExplanded(!filtersExplanded);
                                    }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>
                                            {filtersExplanded
                                                ? "Hide filters"
                                                : "Show filters"}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {/* School & location */}
                                        <Box sx={{ display: "block" }}>
                                            <FormControl
                                                sx={{
                                                    mt: 1,
                                                    ml: 1,
                                                    mb: 0,
                                                    width: 200,
                                                }}
                                                size="small"
                                            >
                                                <InputLabel id="demo-select-small">
                                                    School
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-select-small"
                                                    id="demo-select-small"
                                                    value={schoolDropdown}
                                                    label="Manufacturer"
                                                    onChange={
                                                        handleSelectedSchoolChange
                                                    }
                                                >
                                                    {schools.length !== 0 &&
                                                        schools.map(
                                                            (sch, index) => (
                                                                <MenuItem
                                                                    key={index}
                                                                    value={
                                                                        sch.id
                                                                    }
                                                                >
                                                                    {sch.name}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                </Select>
                                            </FormControl>

                                            <Button
                                                variant="contained"
                                                className="btn_tiny"
                                                sx={{
                                                    mt: 1,
                                                    backgroundColor: "white",
                                                    color: "black",
                                                    border: "1px solid lightgray",
                                                    boxShadow: "none",
                                                    height: 40,
                                                }}
                                                onClick={() => {
                                                    setSchoolDropdown("");
                                                    setSelectedSchool("");
                                                    setSelectedSchoolObj(null);
                                                    setLocationDropdown("");
                                                }}
                                            >
                                                x
                                            </Button>

                                            <FormControl
                                                sx={{
                                                    mt: 1,
                                                    ml: 1,
                                                    mb: 0,
                                                    width: 200,
                                                }}
                                                size="small"
                                            >
                                                <InputLabel id="demo-select-small">
                                                    Location
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-select-small"
                                                    id="demo-select-small"
                                                    value={locationDropdown}
                                                    label="Location"
                                                    onChange={
                                                        handleSelectedLocationChange
                                                    }
                                                >
                                                    {selectedSchool !== "" &&
                                                        schools
                                                            .filter(
                                                                (s) =>
                                                                    s.id ===
                                                                    selectedSchool
                                                            )[0]
                                                            .locations.map(
                                                                (
                                                                    loc,
                                                                    index
                                                                ) => (
                                                                    <MenuItem
                                                                        key={
                                                                            index
                                                                        }
                                                                        value={
                                                                            loc.id
                                                                        }
                                                                    >
                                                                        {
                                                                            loc.description
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                </Select>
                                            </FormControl>
                                            <Button
                                                variant="contained"
                                                className="btn_tiny"
                                                sx={{
                                                    mt: 1,
                                                    backgroundColor: "white",
                                                    color: "black",
                                                    border: "1px solid lightgray",
                                                    boxShadow: "none",
                                                    height: 40,
                                                }}
                                                onClick={() => {
                                                    setLocationDropdown("");
                                                }}
                                            >
                                                x
                                            </Button>

                                            <FormControl
                                                sx={{
                                                    mt: 1,
                                                    ml: 1,
                                                    mb: 0,
                                                    width: 200,
                                                }}
                                                size="small"
                                            >
                                                <InputLabel id="demo-select-small">
                                                    Category
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-select-small"
                                                    id="demo-select-small"
                                                    value={categoryDropdown}
                                                    label="Category"
                                                    onChange={
                                                        handleSelectedCategoryChange
                                                    }
                                                >
                                                    {session.environment?.categories.map(
                                                        (cat, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={
                                                                    cat.categoryID
                                                                }
                                                            >
                                                                {
                                                                    cat.categoryName
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>

                                            <Button
                                                variant="contained"
                                                className="btn_tiny"
                                                sx={{
                                                    mt: 1,
                                                    backgroundColor: "white",
                                                    color: "black",
                                                    border: "1px solid lightgray",
                                                    boxShadow: "none",
                                                    height: 40,
                                                }}
                                                onClick={() => {
                                                    setCategoryDropdown("");
                                                    setSubcategoryDropdown("");
                                                }}
                                            >
                                                x
                                            </Button>

                                            <FormControl
                                                sx={{
                                                    mt: 1,
                                                    ml: 1,
                                                    mb: 0,
                                                    width: 200,
                                                }}
                                                size="small"
                                            >
                                                <InputLabel id="demo-select-small">
                                                    Subcategory
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-select-small"
                                                    id="demo-select-small"
                                                    value={subcategoryDropdown}
                                                    label="Subcategory"
                                                    onChange={
                                                        handleSelectedSubcategoryChange
                                                    }
                                                >
                                                    {/* if categoryDropdown is not null, then filter the subcategories by categoryID */}

                                                    {categoryDropdown
                                                        ? session.environment?.subcategories
                                                            .filter(
                                                                (subcat) =>
                                                                    subcat.categoryID ===
                                                                    categoryDropdown
                                                            )
                                                            .map(
                                                                (
                                                                    subcat,
                                                                    index
                                                                ) => (
                                                                    <MenuItem
                                                                        key={
                                                                            index
                                                                        }
                                                                        value={
                                                                            subcat.subcategoryID
                                                                        }
                                                                    >
                                                                        {
                                                                            subcat.subcategoryName
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )
                                                        : session.environment?.subcategories.map(
                                                            (
                                                                subcat,
                                                                index
                                                            ) => (
                                                                <MenuItem
                                                                    key={
                                                                        index
                                                                    }
                                                                    value={
                                                                        subcat.subcategoryID
                                                                    }
                                                                >
                                                                    {
                                                                        subcat.subcategoryName
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                </Select>
                                            </FormControl>

                                            <Button
                                                variant="contained"
                                                className="btn_tiny"
                                                sx={{
                                                    mt: 1,
                                                    backgroundColor: "white",
                                                    color: "black",
                                                    border: "1px solid lightgray",
                                                    boxShadow: "none",
                                                    height: 40,
                                                }}
                                                onClick={() => {
                                                    setSubcategoryDropdown("");
                                                }}
                                            >
                                                x
                                            </Button>
                                        </Box>

                                        {/* Status & Action type */}
                                        <Box sx={{ display: "block" }}>
                                            <FormControl
                                                sx={{
                                                    mt: 1,
                                                    ml: 1,
                                                    mb: 0,
                                                    width: 200,
                                                }}
                                                size="small"
                                            >
                                                <InputLabel id="demo-select-small">
                                                    Status
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-select-small"
                                                    id="demo-select-small"
                                                    value={statusDropdown}
                                                    label="Status"
                                                    onChange={
                                                        handleSelectedStatusChange
                                                    }
                                                >
                                                    {session.environment?.statustype.map(
                                                        (sts, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={
                                                                    sts.statusTypeID
                                                                }
                                                            >
                                                                {
                                                                    sts.statusLabel
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>

                                            <Button
                                                variant="contained"
                                                className="btn_tiny"
                                                sx={{
                                                    mt: 1,
                                                    backgroundColor: "white",
                                                    color: "black",
                                                    border: "1px solid lightgray",
                                                    boxShadow: "none",
                                                    height: 40,
                                                }}
                                                onClick={() => {
                                                    setStatusDropdown("");
                                                }}
                                            >
                                                x
                                            </Button>

                                            <FormControl
                                                sx={{
                                                    mt: 1,
                                                    ml: 1,
                                                    mb: 0,
                                                    width: 200,
                                                }}
                                                size="small"
                                            >
                                                <InputLabel id="demo-select-small">
                                                    Action type
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-select-small"
                                                    id="demo-select-small"
                                                    value={actionTypeDropdown}
                                                    label="Action type"
                                                    onChange={
                                                        handleSelectedActionTypeChange
                                                    }
                                                >
                                                    {session.environment?.actiontype.map(
                                                        (act, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={
                                                                    act.actionTypeID
                                                                }
                                                            >
                                                                {act.actionName}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                            <Button
                                                variant="contained"
                                                className="btn_tiny"
                                                sx={{
                                                    mt: 1,
                                                    backgroundColor: "white",
                                                    color: "black",
                                                    border: "1px solid lightgray",
                                                    boxShadow: "none",
                                                    height: 40,
                                                }}
                                                onClick={() => {
                                                    setActionTypeDropdown("");
                                                }}
                                            >
                                                x
                                            </Button>
                                            <TextField
                                                sx={{
                                                    mt: 1,
                                                    ml: 1,
                                                    mb: 0,
                                                    width: 200,
                                                }}
                                                label="Email address"
                                                size="small"
                                                value={email}
                                                onChange={handleEmailChange}
                                            />
                                            <Button
                                                variant="contained"
                                                className="btn_tiny"
                                                sx={{
                                                    mt: 1,
                                                    backgroundColor: "white",
                                                    color: "black",
                                                    border: "1px solid lightgray",
                                                    boxShadow: "none",
                                                    height: 40,
                                                }}
                                                onClick={() => {
                                                    setEmail("");
                                                }}
                                            >
                                                x
                                            </Button>

                                            <FormControl
                                                sx={{
                                                    mt: 1,
                                                    ml: 1,
                                                    mb: 0,
                                                    width: 200,
                                                }}
                                                size="small"
                                            >
                                                <InputLabel id="demo-select-small">
                                                    Manufacturer
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-select-small"
                                                    id="demo-select-small"
                                                    value={manufacturerDropdown}
                                                    label="Manufacturer"
                                                    onChange={
                                                        handleSelectedManufacturerChange
                                                    }
                                                >
                                                    {session.environment?.manufacturers.map(
                                                        (man, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={
                                                                    man.manufacturerID
                                                                }
                                                            >
                                                                {
                                                                    man.manufacturerName
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>

                                            <Button
                                                variant="contained"
                                                className="btn_tiny"
                                                sx={{
                                                    mt: 1,
                                                    backgroundColor: "white",
                                                    color: "black",
                                                    border: "1px solid lightgray",
                                                    boxShadow: "none",
                                                    height: 40,
                                                }}
                                                onClick={() => {
                                                    setManufacturerDropdown("");
                                                }}
                                            >
                                                x
                                            </Button>
                                        </Box>

                                        {/* Model nr ,  product name & serial nr */}
                                        <Box sx={{ display: "block" }}>
                                            <TextField
                                                sx={{
                                                    mt: 1,
                                                    ml: 1,
                                                    mb: 0,
                                                    width: 200,
                                                }}
                                                label="Model nr"
                                                size="small"
                                                value={modelNumber}
                                                onChange={handleModelNrChange}
                                            />
                                            <Button
                                                variant="contained"
                                                className="btn_tiny"
                                                sx={{
                                                    mt: 1,
                                                    backgroundColor: "white",
                                                    color: "black",
                                                    border: "1px solid lightgray",
                                                    boxShadow: "none",
                                                    height: 40,
                                                }}
                                                onClick={() => {
                                                    setModelNumber("");
                                                }}
                                            >
                                                x
                                            </Button>

                                            <TextField
                                                sx={{
                                                    mt: 1,
                                                    ml: 1,
                                                    mb: 0,
                                                    width: 200,
                                                }}
                                                label="Product name"
                                                size="small"
                                                value={productName}
                                                onChange={
                                                    handleProductNameChange
                                                }
                                            />
                                            <Button
                                                variant="contained"
                                                className="btn_tiny"
                                                sx={{
                                                    mt: 1,
                                                    backgroundColor: "white",
                                                    color: "black",
                                                    border: "1px solid lightgray",
                                                    boxShadow: "none",
                                                    height: 40,
                                                }}
                                                onClick={() => {
                                                    setProductName("");
                                                }}
                                            >
                                                x
                                            </Button>

                                            <TextField
                                                sx={{
                                                    mt: 1,
                                                    ml: 1,
                                                    mb: 0,
                                                    width: 200,
                                                }}
                                                label="Serial nr"
                                                size="small"
                                                value={serialNumber}
                                                onChange={
                                                    handleSerialNumberChange
                                                }
                                            />
                                            <Button
                                                variant="contained"
                                                className="btn_tiny"
                                                sx={{
                                                    mt: 1,
                                                    backgroundColor: "white",
                                                    color: "black",
                                                    border: "1px solid lightgray",
                                                    boxShadow: "none",
                                                    height: 40,
                                                }}
                                                onClick={() => {
                                                    setSerialNumber("");
                                                }}
                                            >
                                                x
                                            </Button>
                                        </Box>

                                        {/* PurchaseDate from , purchase date to & supplier */}
                                        <Box sx={{ display: "block" }}>
                                            <TextField
                                                sx={{
                                                    mt: 1,
                                                    ml: 1,
                                                    mb: 0,
                                                    width: 200,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                label="Invoice date from"
                                                size="small"
                                                type="date"
                                                value={purchaseDateFrom}
                                                onChange={
                                                    handlePurchaseDateFromChange
                                                }
                                            />
                                            <Button
                                                variant="contained"
                                                className="btn_tiny"
                                                sx={{
                                                    mt: 1,
                                                    backgroundColor: "white",
                                                    color: "black",
                                                    border: "1px solid lightgray",
                                                    boxShadow: "none",
                                                    height: 40,
                                                }}
                                                onClick={() => {
                                                    setPurchaseDateFrom("");
                                                }}
                                            >
                                                x
                                            </Button>

                                            <TextField
                                                sx={{
                                                    mt: 1,
                                                    ml: 1,
                                                    mb: 0,
                                                    width: 200,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                label="Invoice date to"
                                                size="small"
                                                type="date"
                                                value={purchaseDateTo}
                                                onChange={
                                                    handlePurchaseDateToChange
                                                }
                                            />
                                            <Button
                                                variant="contained"
                                                className="btn_tiny"
                                                sx={{
                                                    mt: 1,
                                                    backgroundColor: "white",
                                                    color: "black",
                                                    border: "1px solid lightgray",
                                                    boxShadow: "none",
                                                    height: 40,
                                                }}
                                                onClick={() => {
                                                    setPurchaseDateTo("");
                                                }}
                                            >
                                                x
                                            </Button>

                                            <FormControl
                                                sx={{
                                                    mt: 1,
                                                    ml: 1,
                                                    mb: 0,
                                                    width: 200,
                                                }}
                                                size="small"
                                            >
                                                <InputLabel id="demo-select-small">
                                                    Suppliers
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-select-small"
                                                    id="demo-select-small"
                                                    value={supplierDropdown}
                                                    label="Suppliers"
                                                    onChange={
                                                        handleSelectedSupplierChange
                                                    }
                                                >
                                                    {session.environment?.suppliers.map(
                                                        (sup, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={
                                                                    sup.SupplierID
                                                                }
                                                            >
                                                                {
                                                                    sup.SupplierName
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                            <Button
                                                variant="contained"
                                                className="btn_tiny"
                                                sx={{
                                                    mt: 1,
                                                    backgroundColor: "white",
                                                    color: "black",
                                                    border: "1px solid lightgray",
                                                    boxShadow: "none",
                                                    height: 40,
                                                }}
                                                onClick={() => {
                                                    setSupplierDropdown("");
                                                }}
                                            >
                                                x
                                            </Button>

                                            <TextField
                                                sx={{
                                                    mt: 1,
                                                    ml: 1,
                                                    mb: 0,
                                                    width: 200,
                                                }}
                                                label="Price VAT incl from"
                                                size="small"
                                                type="number"
                                                value={priceVATinclFrom}
                                                onChange={
                                                    handlePriceVATinclFromChange
                                                }
                                            />
                                            <Button
                                                variant="contained"
                                                className="btn_tiny"
                                                sx={{
                                                    mt: 1,
                                                    backgroundColor: "white",
                                                    color: "black",
                                                    border: "1px solid lightgray",
                                                    boxShadow: "none",
                                                    height: 40,
                                                }}
                                                onClick={() => {
                                                    setPriceVATinclFrom("");
                                                }}
                                            >
                                                x
                                            </Button>
                                        </Box>

                                        {/* invoiceNrExt , invoiceNrInt & invoiceNrSch */}
                                        <Box sx={{ display: "block" }}>
                                            <TextField
                                                sx={{
                                                    mt: 1,
                                                    ml: 1,
                                                    mb: 0,
                                                    width: 200,
                                                }}
                                                label="Invoice nr ext"
                                                size="small"
                                                value={invoiceNrExt}
                                                onChange={
                                                    handleInvoiceNrExtChange
                                                }
                                            />
                                            <Button
                                                variant="contained"
                                                className="btn_tiny"
                                                sx={{
                                                    mt: 1,
                                                    backgroundColor: "white",
                                                    color: "black",
                                                    border: "1px solid lightgray",
                                                    boxShadow: "none",
                                                    height: 40,
                                                }}
                                                onClick={() => {
                                                    setInvoiceNrExt("");
                                                }}
                                            >
                                                x
                                            </Button>

                                            <TextField
                                                sx={{
                                                    mt: 1,
                                                    ml: 1,
                                                    mb: 0,
                                                    width: 200,
                                                }}
                                                label="Invoice nr int"
                                                size="small"
                                                value={invoiceNrInt}
                                                onChange={
                                                    handleInvoiceNrIntChange
                                                }
                                            />
                                            <Button
                                                variant="contained"
                                                className="btn_tiny"
                                                sx={{
                                                    mt: 1,
                                                    backgroundColor: "white",
                                                    color: "black",
                                                    border: "1px solid lightgray",
                                                    boxShadow: "none",
                                                    height: 40,
                                                }}
                                                onClick={() => {
                                                    setInvoiceNrInt("");
                                                }}
                                            >
                                                x
                                            </Button>

                                            <TextField
                                                sx={{
                                                    mt: 1,
                                                    ml: 1,
                                                    mb: 0,
                                                    width: 200,
                                                }}
                                                label="Invoice nr sch"
                                                size="small"
                                                value={invoiceNrSch}
                                                onChange={
                                                    handleInvoiceNrSchChange
                                                }
                                            />
                                            <Button
                                                variant="contained"
                                                className="btn_tiny"
                                                sx={{
                                                    mt: 1,
                                                    backgroundColor: "white",
                                                    color: "black",
                                                    border: "1px solid lightgray",
                                                    boxShadow: "none",
                                                    height: 40,
                                                }}
                                                onClick={() => {
                                                    setInvoiceNrSch("");
                                                }}
                                            >
                                                x
                                            </Button>

                                            <TextField
                                                sx={{
                                                    mt: 1,
                                                    ml: 1,
                                                    mb: 0,
                                                    width: 200,
                                                }}
                                                label="Price VAT incl to"
                                                size="small"
                                                type="number"
                                                value={priceVATinclTo}
                                                onChange={
                                                    handlePriceVATinclToChange
                                                }
                                            />
                                            <Button
                                                variant="contained"
                                                className="btn_tiny"
                                                sx={{
                                                    mt: 1,
                                                    backgroundColor: "white",
                                                    color: "black",
                                                    border: "1px solid lightgray",
                                                    boxShadow: "none",
                                                    height: 40,
                                                }}
                                                onClick={() => {
                                                    setPriceVATinclTo("");
                                                }}
                                            >
                                                x
                                            </Button>
                                        </Box>

                                        <Box sx={{ display: "block" }}>
                                            <Button
                                                sx={{ mt: 1, mr: 1 }}
                                                variant="contained"
                                                onClick={handleApplyFilters}
                                            >
                                                Apply filters
                                            </Button>
                                            <Button
                                                sx={{ mt: 1 }}
                                                variant="contained"
                                                onClick={handleClearFilters}
                                            >
                                                Clear filters
                                            </Button>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>

                                {/* <Box pt={0} sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
                                        <Tab label="Single select/overview" {...a11yProps(0)} />
                                        <Tab disabled={true} label="Multy select" {...a11yProps(1)} />
                                    </Tabs>
                                </Box> */}

                                <TabPanel value={tabValue} index={0}>
                                    {filtersExplanded && (
                                        <Box
                                            sx={{
                                                height: 330,
                                                backgroundColor: "#c0e1fc",
                                            }}
                                        >
                                            <DataGrid
                                                rows={products}
                                                columns={columnsProducts}
                                                pageSize={6}
                                                rowsPerPageOptions={[6]}
                                                page={productPage}
                                                onPageChange={(newPage) =>
                                                    setProductPage(newPage)
                                                }
                                                onRowClick={handleRowClick}
                                                onRowDoubleClick={
                                                    handleRowClick
                                                }
                                                selectionModel={selectedProduct}
                                                density="compact"
                                            />
                                        </Box>
                                    )}
                                    {!filtersExplanded && (
                                        <Box
                                            sx={{
                                                height: 600,
                                                backgroundColor: "#c0e1fc",
                                            }}
                                        >
                                            <DataGrid
                                                rows={products}
                                                columns={columnsProducts}
                                                pageSize={13}
                                                rowsPerPageOptions={[13]}
                                                page={productPage}
                                                onPageChange={(newPage) =>
                                                    setProductPage(newPage)
                                                }
                                                onRowClick={handleRowClick}
                                                onRowDoubleClick={
                                                    handleRowClick
                                                }
                                                selectionModel={selectedProduct}
                                                density="compact"
                                            />
                                        </Box>
                                    )}
                                </TabPanel>

                                <TabPanel value={tabValue} index={1}>
                                    <Box
                                        sx={{
                                            height: 330,
                                            backgroundColor: "#90caf9",
                                        }}
                                    >
                                        <DataGrid
                                            rows={products}
                                            columns={columnsProductsMulty}
                                            pageSize={6}
                                            rowsPerPageOptions={[6]}
                                            page={productMultyPage}
                                            onPageChange={(newPage) =>
                                                setProductMultyPage(newPage)
                                            }
                                            checkboxSelection
                                            experimentalFeatures={{}}
                                            onRowClick={
                                                handleRowClick_multiselect
                                            }
                                            onRowDoubleClick={
                                                handleRowClick_multiselect
                                            }
                                            selectionModel={selectedRows}
                                            onSelectionModelChange={
                                                handleSelectionModelChange
                                            }
                                            density="compact"
                                        />
                                    </Box>
                                </TabPanel>
                            </>
                        )}

                        {/*==== Filters ==== */}

                        {/* box align center */}

                        {activeStep === 2 && (
                            <>
                                <Grid item md={12} lg={12}>
                                    <ProductDetails
                                        selectedRows={selectedRows}
                                        schools={schools}
                                        newSchool={productSchool}
                                        setNewSchool={setProductSchool}
                                        newLocation={productLocation}
                                        setNewLocation={setProductLocation}
                                        newInputLocation={productInputLocation}
                                        setNewInputLocation={
                                            setProductInputLocation
                                        }
                                        newRelocationNotes={locationNotes}
                                        setNewRelocationNotes={setLocationNotes}
                                        handleChangeProductsLocation={
                                            handleChangeProductsLocation
                                        }
                                        statuses={
                                            session.environment?.statustype
                                        }
                                        newStatus={productStatus}
                                        setNewStatus={setProductStatus}
                                        // newStatusNotes={productStatusNotes}
                                        // setNewStatusNotes={setProductStatusNotes}
                                        newHistoryNotes={productHistoryNotes}
                                        setNewHistoryNotes={
                                            setProductHistoryNotes
                                        }
                                        handleChangeProductsStatus={
                                            handleChangeProductsStatus
                                        }
                                        categories={
                                            session.environment?.categories
                                        }
                                        newCategory={productCategory}
                                        setNewCategory={setProductCategory}
                                        subcategories={
                                            session.environment?.subcategories
                                        }
                                        newSubcategory={productSubcategory}
                                        setNewSubcategory={
                                            setProductSubcategory
                                        }
                                        manufacturers={
                                            session.environment?.manufacturers
                                        }
                                        newManufacturer={productManufacturer}
                                        setNewManufacturer={
                                            setProductManufacturer
                                        }
                                        newInputManufacturer={
                                            productInputManufacturer
                                        }
                                        setNewInputManufacturer={
                                            setProductInputManufacturer
                                        }
                                        newModelNumber={productModelNumber}
                                        setNewModelNumber={
                                            setProductModelNumber
                                        }
                                        newProductName={productProductName}
                                        setNewProductName={
                                            setProductProductName
                                        }
                                        newSerialNumber={productSerialNumber}
                                        setNewSerialNumber={
                                            setProductSerialNumber
                                        }
                                        newDetailNotes={productDetailNotes}
                                        setNewDetailNotes={
                                            setProductDetailNotes
                                        }
                                        handleChangeProductsDetails={
                                            handleChangeProductsDetails
                                        }
                                        mode="change"
                                        handleCreateProducts={
                                            handleCreateProducts
                                        }
                                        hasPrices="false"
                                        productLastStatusObj={
                                            productLastStatusObj
                                        }
                                        handleDeleteProduct={
                                            handleDialogDeleteProduct
                                        }
                                        insurances={
                                            session.environment?.insurance
                                        }
                                        newInsurance={productInsurance}
                                        setNewInsurance={setProductInsurance}
                                        userInfo={session.user}
                                    />
                                    <Box
                                        sx={{
                                            height: 280,
                                            backgroundColor: "#c0e1fb",
                                        }}
                                    >
                                        <DataGrid
                                            rows={productHistory}
                                            columns={columnsHistory}
                                            pageSize={5}
                                            rowsPerPageOptions={[5]}
                                            experimentalFeatures={{}}
                                            density="compact"
                                            disableSelectionOnClick
                                        />
                                    </Box>
                                    {/* </Card> */}
                                </Grid>
                            </>
                        )}
                        {activeStep === 3 && (
                            <>
                                <Grid item md={12} lg={12}>
                                    <Grid container spacing={2}>
                                        <Grid item md={9} lg={9}>
                                            <Card
                                                variant="outlined"
                                                sx={{
                                                    p: 2,
                                                    ml: 2,
                                                    mr: 2,
                                                    mt: 2,
                                                }}
                                            >                                                
                                                <Typography
                                                    sx={{ fontSize: 16, pb: 0 }}
                                                    color="text.secondary"
                                                    gutterBottom
                                                >
                                                    Invoice details
                                                </Typography>
                                                
                                                <Typography
                                                    sx={{ fontSize: 12, pb: 0 }}
                                                    color="orange"
                                                    gutterBottom
                                                >
                                                    {countConnectedProducts > 0 ? `There is/are ${countConnectedProducts} product(s) connected with this invoice.` : ""}
                                                    
                                                </Typography>



                                                <FormControlLabel
                                                    control={
                                                    <Switch 
                                                        checked={isFileConnected}
                                                        onChange={handleChangeisFileConnected}
                                                        name="isFileConnected" 
                                                        disabled={disableIsFileConnected}
                                                    />
                                                    }
                                                    label={isFileConnected ? "Kies een factuur om te koppelen aan dit product." : "Er zal nooit een factuur worden gekoppeld aan dit item. Let op, deze keuze is definitief!"}
                                                    
                                                />


                                                <Box
                                                    sx={{
                                                        mt: 0,
                                                        mb: 2,
                                                        minHeight: 40,
                                                    }}
                                                >
                                                    <Stack
                                                        direction="row"
                                                        flexWrap="wrap"
                                                        spacing={0}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: 14,
                                                                pt: 1,
                                                                pb: 0,
                                                                pr: 1,
                                                            }}
                                                            color="text.primary"
                                                            gutterBottom
                                                        >
                                                            Connected invoice file
                                                        </Typography>
                                                        {newURLFiles.length >
                                                            0 &&
                                                            newURLFiles[0].id !==
                                                            null ? (
                                                            newURLFiles.map(
                                                                (file) => (
                                                                    <Chip
                                                                        sx={{
                                                                            height: 40,
                                                                            pl: 2,
                                                                            pr: 2,
                                                                        }}
                                                                        icon={
                                                                            <FileOpenIcon />
                                                                        }
                                                                        key={
                                                                            file.id
                                                                        }
                                                                        label={
                                                                            file.name
                                                                        }
                                                                        clickable
                                                                        //  deleteIcon={existingPurchase === null ?
                                                                            deleteIcon={disableCanRemoveFile ?
                                                                            <></>
                                                                        : <DeleteIcon />
                                                                        }
                                                                        
                                                                        onClick={() => {
                                                                            window.open(
                                                                                "https://drive.google.com/file/d/" +
                                                                                file.id +
                                                                                "/view",
                                                                                "_blank"
                                                                            );
                                                                        }}
                                                                        onDelete={handleDeleteFile(
                                                                            file.id
                                                                        )}
                                                                    />
                                                                )
                                                            )
                                                        ) : (
                                                            <Chip
                                                            sx={{
                                                                height: 40,
                                                                pl: 2,
                                                                pr: 2,
                                                            }}
                                                            label={
                                                                "No file"
                                                            }
                                                        />
                                                        )}

                                                    </Stack>

                                                </Box>





                                                <Box sx={{  }}>

                                                {/* <FormControl
                                                    sx={{ minWidth: "45%", mr:2 }}
                                                    size="small"
                                                    display="none"
                                                >
                                                    <InputLabel id="demo-select-small">
                                                        Supplier *
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-select-small"
                                                        id="demo-select-small"
                                                        value={newSupplierID}
                                                        label="Supplier"
                                                        onChange={
                                                            handleSelectedNewSupplier
                                                        }
                                                        required={true}
                                                    >
                                                        {session.environment?.suppliers.map(
                                                            (sup, index) => (
                                                                <MenuItem
                                                                    key={index}
                                                                    value={
                                                                        sup.SupplierID
                                                                    }
                                                                >
                                                                    {
                                                                        sup.SupplierName
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl> */}

                                                <FormControl
                                                    sx={{ minWidth: "45%", mr:2 }}
                                                    size="small"
                                                >
                                                                                               
                                                    <Autocomplete
                                                        value={optionSupplier}
                                                        onChange={handleOptionSupplierChange}
                                                        inputValue={inputSupplier}
                                                        onInputChange={handleInputSupplierChange}
                                                        id="combo-box-demo2"
                                                        freeSolo
                                                        options={
                                                            session.environment?.suppliers.map((option) => option.SupplierName)
                                                        }
                                                        renderInput={(params) => (
                                                            <TextField required {...params} label="Select or enter supplier" variant="outlined" fullWidth />
                                                        )}
                                                        size="small"
                                                        disabled={disableSupplier}
                                                    />
                                                </FormControl>

                                                <TextField
                                                    sx={{
                                                        width: "45%",
                                                    }}
                                                    fullWidth
                                                    label="Invoice date"
                                                    id="PurchaseDate"
                                                    variant="outlined"
                                                    value={newPurchaseDate}
                                                    size="small"
                                                    type="date"
                                                    onChange={
                                                        handleChangeNewPurchaseDate
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    required={true}
                                                    disabled={disablePurchaseDate}
                                                />

                                                </Box>


                                                <Box sx={{ mt: 2 }}>
                                                    <TextField
                                                    sx={{ width:"45%",mr:2 }}
                                                    fullWidth
                                                    label="invoice Nr Int"
                                                    id="InvoiceNrInt"
                                                    variant="outlined"
                                                    value={newInvoiceNrInt}
                                                    size="small"
                                                    onChange={
                                                        handleChangeNewInvoiceNrInt
                                                    }
                                                    disabled={disableInvoiceNrInt}
                                                />
                                                <TextField
                                                    sx={{ width:"45%"  }}
                                                    fullWidth
                                                    label="Notes"
                                                    id="Notes"
                                                    variant="outlined"
                                                    value={newPurchaseNotes}
                                                    size="small"
                                                    onChange={
                                                        handleChangeNewPurchaseNotes
                                                    }
                                                    disabled={disablePurchaseNotes}
                                                />
                                                </Box>

                                                <Typography
                                                    sx={{ fontSize: 16, pt: 2 }}
                                                    color="text.secondary"
                                                    gutterBottom
                                                >
                                                    Product details
                                                </Typography>


                                                <Box sx={{ mt: 2 }}>
                                                    <TextField
                                                        sx={{
                                                            pt: 0,
                                                            pb: 1,
                                                            ml: 0,
                                                            width: "110px",
                                                        }}
                                                        id="PriceExclVAT"
                                                        size="small"
                                                        label="Price Excl. VAT"
                                                        type="number"
                                                        value={priceVATexcl}
                                                        onChange={(e) =>
                                                            setPriceVATexcl(
                                                                e.target.value
                                                            )
                                                        }
                                                    />

                                                    <Tooltip
                                                        title="Calculate the price excluding VAT with rounding to two decimal places"
                                                        placement="top"
                                                    >
                                                        <IconButton
                                                            aria-label="open folder"
                                                            sx={{
                                                                mt: 0,
                                                                ml: 0,
                                                                mb: 1,
                                                                width: 30,
                                                            }}
                                                            size="big"
                                                            onClick={
                                                                calculatePriceVATexcluded
                                                            }
                                                        >
                                                            <KeyboardArrowLeftIcon
                                                                sx={{
                                                                    pt: 0,
                                                                    pl: 0,
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>

                                                    <TextField
                                                        sx={{
                                                            pt: 0,
                                                            pb: 1,
                                                            ml: 0,
                                                            width: "75px",
                                                        }}
                                                        id="VAT"
                                                        size="small"
                                                        label="VAT"
                                                        type="number"
                                                        value={VAT}
                                                        onChange={(e) =>
                                                            setVAT(
                                                                e.target.value
                                                            )
                                                        }
                                                    />

                                                    <Tooltip
                                                        title="Calculate the price including VAT with rounding to two decimal places"
                                                        placement="top"
                                                    >
                                                        <IconButton
                                                            aria-label="open folder"
                                                            sx={{
                                                                mt: 0,
                                                                ml: 0,
                                                                mb: 1,
                                                                width: 30,
                                                            }}
                                                            size="big"
                                                            onClick={
                                                                calculatePriceVATincluded
                                                            }
                                                        >
                                                            <KeyboardArrowRightIcon
                                                                sx={{
                                                                    pt: 0,
                                                                    pl: 0,
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>

                                                    <TextField
                                                        sx={{
                                                            pt: 0,
                                                            pb: 1,
                                                            ml: 0,
                                                            mr:2,
                                                            width: "110px",
                                                        }}
                                                        id="PriceInclVAT"
                                                        size="small"
                                                        type="number"
                                                        label="Price Incl. VAT"
                                                        value={priceVATincl}
                                                        onChange={(e) =>
                                                            setPriceVATincl(
                                                                e.target.value
                                                            )
                                                        }
                                                    />

                                                    <Box component={"span"} >
                                                        <Button
                                                            sx={{pt: 1, mt: 0, ml: 0, mb: 1, width: "45%",
                                                            // display if disableAddPurchase is true
                                                            display: !disableAddPurchase ? "" : "none"
                                                            }}
                                                            disabled={
                                                                disableAddPurchase
                                                            }
                                                            variant="contained"
                                                            onClick={
                                                                prepareInvoice
                                                            }
                                                        >
                                                            {
                                                                addSavePurchaseButtonLabel
                                                            }
                                                        </Button>
                                                        <Button
                                                            sx={{pt: 1, mt: 0, ml: 0, mb: 1, width: "45%",
                                                                display: !disableChangePurchase ? "" : "none"
                                                            }}
                                                            disabled={
                                                                disableChangePurchase
                                                            }
                                                            variant="contained"
                                                            onClick={
                                                                handleConnectToThisPurshase
                                                            }
                                                        >
                                                            Connect with this invoice
                                                        </Button>
                                                    </Box>

                                                </Box>
                                            </Card>

                                            <Box sx={{ ml: 0, mt: 1 }}>
                                                {existingPurchase !== null && (
                                                    <Box sx={{ mt: 1, ml: 2 }}>
                                                        <Typography
                                                            color="orange"
                                                            sx={{ fontSize: 12 }}
                                                        >
                                                            This product is already connected to above invoice.
                                                        </Typography>
                                                    </Box>
                                                )}
                                                {existingPurchase === null && (
                                                    <Box sx={{ mt: 1, ml: 2 }}>
                                                        <Typography
                                                            color="red"
                                                            sx={{ fontSize: 12 }}
                                                        >
                                                            This product is not connected to any invoice yet.
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </Box>
                                        </Grid>

                                        <Grid item md={3} lg={3}>

                                            <Card
                                                variant="outlined"
                                                sx={{
                                                    p: 2,
                                                    ml: 0,
                                                    mr: 2,
                                                    mt: 2,
                                                }}
                                            >
                                                <Typography
                                                    sx={{ fontSize: 16, pb: 2 }}
                                                    color="text.secondary"
                                                    gutterBottom
                                                >
                                                    Google workspace files
                                                </Typography>

                                                <FormControl
                                                    sx={{
                                                        mt: 0,
                                                        ml: 0,
                                                        mb: 1,
                                                        width: 185,
                                                    }}
                                                    size="small"
                                                >
                                                    <InputLabel id="demo-select-small">
                                                        Shared drive
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-select-small"
                                                        id="demo-select-small"
                                                        value={driveDropdown}
                                                        label="Shared drive"
                                                        onChange={
                                                            handleSelectedDriveChange
                                                        }
                                                    >
                                                        {session.environment?.drives.map(
                                                            (drv, index) => (
                                                                <MenuItem
                                                                    key={index}
                                                                    value={
                                                                        drv.driveGUID
                                                                    }
                                                                >
                                                                    {
                                                                        drv.driveName
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                                {driveDropdown !== "" && (
                                                    <Tooltip
                                                        title="Open shared folder in new tab"
                                                        placement="top"
                                                    >
                                                        <IconButton
                                                        label="Open folder"
                                                            aria-label="open folder"
                                                            sx={{
                                                                mt: 0,
                                                                ml: 1,
                                                                mb: 1,
                                                            }}
                                                            size="big"
                                                            onClick={() => {
                                                                var drive =
                                                                    session.environment?.drives.filter(
                                                                        (drv) =>
                                                                            drv.driveGUID ===
                                                                            driveDropdown
                                                                    )[0];
                                                                window.open(
                                                                    "https://drive.google.com/drive/folders/" +
                                                                    drive.driveID,
                                                                    "_blank"
                                                                );
                                                            }}
                                                        >
                                                            <FolderSharedIcon
                                                                sx={{
                                                                    pt: 0,
                                                                    pl: 0,
                                                                    width: 20,
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}

                                                <Box
                                                    align="left"
                                                    sx={{ mr: 2 }}
                                                >

                                                        <TextField
                                                            sx={{
                                                                mr: 2,
                                                                width: 240,
                                                            }}
                                                            label="Filter files by name"
                                                            id="FilterFilesByName"
                                                            variant="outlined"
                                                            value={
                                                                filterFilesByName
                                                            }
                                                            size="small"
                                                            onChange={
                                                                handleChangeFilterFilesByName
                                                            }
                                                        />
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                backgroundColor:
                                                                    "#42a5f5",
                                                                width: 240,
                                                                mt:1
                                                            }}
                                                            // startIcon={<CallReceivedIcon />}
                                                            onClick={
                                                                handleGetFilesSharedDrive
                                                            }
                                                        >
                                                            Show files in drive
                                                        </Button>

                                                </Box>
                                            </Card>


                                        </Grid>

                                        {/* <Grid item md={6} lg={6}>
                                            <Box align="right" sx={{ mr: 2 }}>
                                                <Button
                                                    variant="contained"
                                                    sx={{ backgroundColor: "#42a5f5" }}
                                                    startIcon={<CallReceivedIcon />}
                                                    onClick={handleGetFiles}
                                                >
                                                    Show Files in folder
                                                </Button>
                                            </Box>
                                        </Grid> */}

                                        <Grid item md={12} lg={12}>
                                            <Box
                                                sx={{
                                                    height: 356,
                                                    width: "100%",
                                                    backgroundColor: "#90caf9",
                                                }}
                                            >
                                                {files.length > 0 && (
                                                    <DataGrid
                                                        //rows are the locations of the selected school
                                                        rows={files}
                                                        columns={columnsFiles}
                                                        pageSize={100}
                                                        rowsPerPageOptions={[
                                                            100,
                                                        ]}
                                                        page={filesPage}
                                                        onPageChange={(
                                                            newPage
                                                        ) =>
                                                            setFilesPage(
                                                                newPage
                                                            )
                                                        }
                                                        //checkboxSelection
                                                        //disableSelectionOnClick

                                                        experimentalFeatures={{}}
                                                        // editMode="none"
                                                        onRowClick={
                                                            handleRowClickFile
                                                        }
                                                        //onRowDoubleClick={handleRowDoubleClickFile}
                                                        selectionModel={
                                                            selectedFiles
                                                        }
                                                        onSelectionModelChange={
                                                            handleSelectionFileModelChange
                                                        }
                                                        density="compact"
                                                    />
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        {/* 
                        {activeStep === 4 && (
                            <>
                                <Grid item md={12} lg={12}>
                                    <Card variant="outlined" sx={{ p: 2, mt: 0 }}>
                                        <FormControl sx={{ minWidth: 300 }} size="small">
                                            <InputLabel id="demo-select-small">Suppliers</InputLabel>
                                            <Select
                                                labelId="demo-select-small"
                                                id="demo-select-small"
                                                value={filterPurchaseSupplier}
                                                label="Suppliers"
                                                onChange={handleSelectedSupplierPurchaseChange}
                                            >
                                                {session.environment?.suppliers.map((sup, index) => (
                                                    <MenuItem key={index} value={sup.SupplierID}>
                                                        {sup.SupplierName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        <TextField
                                            InputLabelProps={{ shrink: true }}
                                            label="From date"
                                            size="small"
                                            type="date"
                                            value={filterPurchaseFrom_date}
                                            onChange={handleFrom_dateChange}
                                        />
                                        <TextField
                                            InputLabelProps={{ shrink: true }}
                                            label="To date"
                                            size="small"
                                            type="date"
                                            value={filterPurchaseTo_date}
                                            onChange={handleTo_dateChange}
                                        />

                                        <TextField
                                            label="invoiceNrExt"
                                            size="small"
                                            value={filterPurchaseInvoiceNrExt}
                                            sx={{ width: 150 }}
                                            onChange={(e) => setFilterPurchaseInvoiceNrExt(e.target.value)}
                                        />
                                        <TextField
                                            label="invoiceNrInt"
                                            size="small"
                                            value={filterPurchaseInvoiceNrInt}
                                            sx={{ width: 150 }}
                                            onChange={(e) => setFilterPurchaseInvoiceNrInt(e.target.value)}
                                        />
                                        <TextField
                                            label="invoiceNrSch"
                                            size="small"
                                            value={filterPurchaseInvoiceNrSch}
                                            sx={{ width: 150 }}
                                            onChange={(e) => setFilterPurchaseInvoiceNrSch(e.target.value)}
                                        />

                                        <Button variant="contained" onClick={handleApplyFiltersPurchase}>
                                            Apply filters
                                        </Button>
                                        <Button variant="contained" onClick={handleClearFiltersPurchase}>
                                            Clear filters
                                        </Button>

                                        <Box sx={{ height: 330, backgroundColor: "#90caf9" }}>

                                            <DataGrid
                                                rows={purchases}
                                                columns={columnPurchases}
                                                pageSize={6}
                                                rowsPerPageOptions={[6]}
                                                page={purchasePage}
                                                onPageChange={(newPage) => setPurchasePage(newPage)}
                                                experimentalFeatures={{}}
                                                onRowClick={handleRowClickPurchase}
                                                onRowDoubleClick={handleRowClickPurchase}
                                                selectionModel={selectedPurchase}
                                                density="compact"
                                            />

                                        </Box>
                                        <Button disabled={addInPurchaseDisabled} variant="contained" onClick={handleAddProductInPurchaseClicked}>
                                            Add product in invoice
                                        </Button>
                                    </Card>
                                </Grid>
                                <Grid item md={12} lg={12}>
                                    <Card variant="outlined" sx={{ p: 2, mt: 1 }}>
                                        <Typography variant="h6">Prices</Typography>

                                        <Box>
                                            <TextField
                                                sx={{ pt: 1, pb: 1, ml: 1, width: "200px" }}
                                                id="PriceExclVAT"
                                                size="small"
                                                label="Price Excl. VAT"
                                                type="number"
                                                value={priceVATexcl}
                                                onChange={(e) => setPriceVATexcl(e.target.value)}
                                            />

                                            <Box pl={1} display={"inline"}>
                                                <Tooltip title="Calculate the price excluding VAT with rounding to two decimal places" arrow>
                                                    <Button size="small" sx={{ mt: 2 }} variant="contained" onClick={calculatePriceVATexcluded}>
                                                        {"<"}
                                                    </Button>
                                                </Tooltip>
                                            </Box>

                                            <TextField
                                                sx={{ pt: 1, pb: 1, ml: 1, width: "200px" }}
                                                id="VAT"
                                                size="small"
                                                label="VAT"
                                                type="number"
                                                value={VAT}
                                                onChange={(e) => setVAT(e.target.value)}
                                            />

                                            <Box pl={1} display={"inline"}>
                                                <Tooltip title="Calculate the price including VAT with rounding to two decimal places." arrow>
                                                    <Button size="small" sx={{ mt: 2 }} variant="contained" onClick={calculatePriceVATincluded}>
                                                        {">"}
                                                    </Button>
                                                </Tooltip>
                                            </Box>

                                            <TextField
                                                sx={{ pt: 1, pb: 1, ml: 1, width: "200px" }}
                                                id="PriceInclVAT"
                                                size="small"
                                                type="number"
                                                label="Price Incl. VAT"
                                                value={priceVATincl}
                                                onChange={(e) => setPriceVATincl(e.target.value)}
                                            />

                                            {"change" === "change" && (
                                                <Box pl={1}>
                                                    <Button disabled={changePricesDisabled} variant="contained" onClick={handleChangePricesClicked}>
                                                        Change prices (for {selectedRows.length} product{selectedRows.length > 1 ? "s" : ""})
                                                    </Button>
                                                </Box>
                                            )}
                                            {selectedPurchase === "" ? (
                                                <Typography sx={{ mt: 1, ml: 2, mb: 1 }} variant="caption" color="primary">
                                                    There is no invoice connected with this product.
                                                </Typography>
                                            ) : null}
                                        </Box>

                                    </Card>
                                </Grid>
                            </>
                        )} */}
                    </Card>
                </Grid>
            </Grid>

            <Grid item md={12} lg={12}>
                <Box maxWidth={800}>
                    <Snackbar
                        open={openMessage}
                        onClose={() => {
                            setOpenMessage(false);
                        }}
                        TransitionComponent={SlideTransition}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        key="Snackbar"
                    //autoHideDuration={3000}
                    >
                        <Alert
                            severity={severity}
                            sx={{ marginTop: 2 }}
                            onClose={() => {
                                setOpenMessage(false);
                            }}
                        >
                            {message}
                        </Alert>
                    </Snackbar>
                </Box>
            </Grid>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Disagree</Button>
                    <Button onClick={handleConfirmDialog} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>

            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
                onClick={handleCloseBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {/* todo add Backdrop to all pages */}

        </Box>
    );
};

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

export default EditProduct;
